import { Position as APIPosition } from 'api';

export interface Position extends APIPosition {
    isTouch?: boolean;
}

class MouseTracker {
    private positions: Position[] = [];
    private canvas: HTMLCanvasElement | null = null;
    private ctx: CanvasRenderingContext2D | null = null;
    private isAnimating: boolean = false;
    private playbackSpeed: number = 1;
    private autoScroll: boolean = true;
    private animationFrameId: number | null = null;
    private onAnimatingChange: (isAnimating: boolean) => void;
    private resizeListener: () => void;

    constructor(containerId: string, onAnimatingChange: (isAnimating: boolean) => void) {
        const container = document.getElementById(containerId);
        if (!container) throw new Error('Container not found');

        this.canvas = document.createElement('canvas');
        this.canvas.style.position = 'absolute';
        this.canvas.style.top = '0';
        this.canvas.style.left = '0';
        this.canvas.style.pointerEvents = 'none';
        this.canvas.style.zIndex = '9999';
        container.appendChild(this.canvas);

        const ctx = this.canvas.getContext('2d');
        if (!ctx) throw new Error('Could not get canvas context');
        this.ctx = ctx;

        this.onAnimatingChange = onAnimatingChange;
        this.resizeListener = () => this.resizeCanvas();
        window.addEventListener('resize', this.resizeListener);
        this.resizeCanvas();
    }

    private resizeCanvas(): void {
        if (!this.canvas) return;
        this.canvas.width = window.innerWidth;
        this.canvas.height = Math.max(
            document.documentElement.scrollHeight,
            document.body.scrollHeight
        );
        // Redraw points after resize if we're animating
        if (this.isAnimating && this.positions.length > 0) {
            this.startAnimation(this.positions, this.playbackSpeed, this.autoScroll);
        }
    }

    public startAnimation(positions: Position[], speed: number = 1, autoScroll: boolean = true): void {
        if (this.isAnimating || !positions.length || !this.ctx || !this.canvas) return;
        
        this.isAnimating = true;
        this.playbackSpeed = speed;
        this.autoScroll = autoScroll;
        this.onAnimatingChange(true);

        // Reset canvas and prepare for animation
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        
        let currentIndex = 0;
        let lastTimestamp = 0;
        let accumulatedTime = 0;
        const timeStep = 16; // ~60fps
        
        const animate = (timestamp: number): void => {
            if (!this.isAnimating || !this.ctx || !this.canvas) return;
            
            if (!lastTimestamp) lastTimestamp = timestamp;
            const deltaTime = timestamp - lastTimestamp;
            lastTimestamp = timestamp;
            
            accumulatedTime += deltaTime * this.playbackSpeed;
            
            while (accumulatedTime >= timeStep && currentIndex < positions.length) {
                const position = positions[currentIndex];
                
                // Handle scrolling before drawing
                if (this.autoScroll) {
                    const viewportHeight = window.innerHeight;
                    const viewportWidth = window.innerWidth;
                    const buffer = 100; // pixels from edge to trigger scroll
                    
                    if (position.x && position.y) {
                        const targetY = position.y - window.scrollY;
                        const targetX = position.x - window.scrollX;
                        
                        if (targetY < buffer || targetY > viewportHeight - buffer ||
                            targetX < buffer || targetX > viewportWidth - buffer) {
                            
                            const scrollX = position.x - viewportWidth / 2;
                            const scrollY = position.y - viewportHeight / 2;
                            
                            // Smooth scroll with easing
                            const currentScrollY = window.scrollY;
                            const currentScrollX = window.scrollX;
                            const scrollDiffY = scrollY - currentScrollY;
                            const scrollDiffX = scrollX - currentScrollX;
                            
                            window.scrollTo({
                                left: currentScrollX + (scrollDiffX * 0.1),
                                top: currentScrollY + (scrollDiffY * 0.1),
                                behavior: 'auto'
                            });
                        }
                    }
                }
                
                // Draw point with fade effect
                const fadeStart = Math.max(positions.length - 200, 0); // Start fading only in last 200 points
                const alpha = currentIndex < fadeStart ? 1 : Math.max(0.2, (positions.length - currentIndex) / 200);
                
                // Draw point with different styles for touch vs mouse
                if (position.x && position.y) {
                    this.ctx.beginPath();
                    this.ctx.arc(position.x, position.y, position.isTouch ? 6 : 4, 0, Math.PI * 2);
                    this.ctx.fillStyle = position.isTouch ? 
                        `rgba(0, 120, 255, ${alpha * 0.5})` : 
                        `rgba(255, 0, 0, ${alpha * 0.5})`;
                    this.ctx.fill();
                    
                    // Draw line to previous point
                    if (currentIndex > 0) {
                        const prevPosition = positions[currentIndex - 1];
                        if (prevPosition.x && prevPosition.y) {
                            this.ctx.beginPath();
                            this.ctx.moveTo(prevPosition.x, prevPosition.y);
                            this.ctx.lineTo(position.x, position.y);
                            this.ctx.strokeStyle = position.isTouch ?
                                `rgba(0, 120, 255, ${alpha * 0.3})` :
                                `rgba(255, 0, 0, ${alpha * 0.3})`;
                            this.ctx.lineWidth = 2;
                            this.ctx.stroke();
                        }
                    }
                }
                
                currentIndex++;
                accumulatedTime -= timeStep;
            }
            
            if (currentIndex < positions.length) {
                this.animationFrameId = requestAnimationFrame(animate);
            } else {
                this.stopAnimation();
            }
        };
        
        this.animationFrameId = requestAnimationFrame(animate);
    }

    public stopAnimation(): void {
        this.isAnimating = false;
        this.onAnimatingChange(false);
        if (this.animationFrameId) {
            cancelAnimationFrame(this.animationFrameId);
            this.animationFrameId = null;
        }
        this.clearCanvas();
    }

    private clearCanvas(): void {
        if (this.ctx && this.canvas) {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        }
    }

    public clearPositions(): void {
        this.stopAnimation();
        this.positions = [];
        this.clearCanvas();
    }

    // Alias for clearPositions to maintain backward compatibility
    public clearTrackingPositions(): void {
        this.clearPositions();
    }

    public addPosition(x: number, y: number, isTouch: boolean = false): void {
        const newPosition: Position = {
            x: Math.round(x),
            y: Math.round(y),
            time: new Date().toISOString(),
            isTouch
        };
        this.positions.push(newPosition);
    }

    public getPositions(): Position[] {
        return [...this.positions];
    }

    public destroy(): void {
        this.stopAnimation();
        this.positions = [];
        window.removeEventListener('resize', this.resizeListener);
        if (this.canvas && this.canvas.parentNode) {
            this.canvas.parentNode.removeChild(this.canvas);
        }
        this.canvas = null;
        this.ctx = null;
    }

    // Alias for destroy to maintain backward compatibility
    public dispose(): void {
        this.destroy();
    }

    public setPlaybackSpeed(speed: number): void {
        this.playbackSpeed = speed;
        if (this.isAnimating && this.positions.length > 0) {
            this.startAnimation(this.positions, this.playbackSpeed, this.autoScroll);
        }
    }

    public getPlaybackSpeed(): number {
        return this.playbackSpeed;
    }

    public setAutoScroll(enabled: boolean): void {
        this.autoScroll = enabled;
    }

    public getAutoScroll(): boolean {
        return this.autoScroll;
    }
}

export default MouseTracker; 