import { Provider } from 'react-redux';
import Pages from 'pages/index';
import '../styles/global.css';
import { setupStore } from 'app/store/store';
import { AlertModal } from 'pages/Modals/Alert';
import UserProvider from './UserProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';

export const store = setupStore();

const App = () => {

  return (
    <Provider store={store}>
      <UserProvider>
        <div className="App">
          <AlertModal>
            <GoogleOAuthProvider clientId="537419973097-gdkhv26c1sbgcua4fml7gb694sso8h6i.apps.googleusercontent.com">
              <Pages />  
            </GoogleOAuthProvider>
          </AlertModal>
        </div>
      </UserProvider>
    </Provider>
  );
};

export {App};