import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { I4Icon, Logo, PunctirLineIcon, TimerBackIcon } from "shared/ui/icons/icons";
import clsx from "clsx";
import { Input } from "shared/ui/Input";
import { Button } from "shared/ui/Button";
import { Modal } from "shared/ui/Modal";
import { Position, useGetAnalyticsInformationVisitsByUserIdQuery, useGetAnalyticsInformationAnalyticsQuery, useGetAnalyticsInformationUsersQuery, useGetAnalyticsInformationVisitsPositionsBySessionIdQuery, usePostAnalyticsInformationAddRecallRequestMutation, usePostAnalyticsInformationAddVisitMutation, UserAnalytics, VisitData } from "api";
import { IS_PC } from "app/store/consts";
import { useDispatch } from "react-redux";
import  { AlertType, alertsSlice } from "app/store/reducers/alertsSlice";
import MouseTracker from "./mouseTracker";
import AnalyticsOverview from "components/AnalyticsOverview";
import { Loader } from "shared/ui/Loader";
import clarity from "@microsoft/clarity";

declare global {
    interface Window {
        ym: Function;
    }
}

const YM_COUNTER_ID = 99669001;

function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

type IPosition = Position & {
    datetime?: string;
};

const InformationPage = () => {
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [analyticsStartDate, setAnalyticsStartDate] = useState<string>("");
    const [analyticsEndDate, setAnalyticsEndDate] = useState<string>("");
    const {setAlert, removeAlert} = alertsSlice.actions;
    const [isAdmin, setIsAdmin] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [showVisits, setShowVisits] = useState(false);
    const [showAnalytics, setShowAnalytics] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const mouseTrackerRef = useRef<MouseTracker | null>(null);
    const lastMousePos = useRef<{x: number, y: number} | null>(null);
    
    const [sessionId] = useState(generateUUID());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPoliticModalOpen, setIsPoliticModalOpen] = useState(false);

    const [addVisit] = usePostAnalyticsInformationAddVisitMutation();
    const [addRecallRequest] = usePostAnalyticsInformationAddRecallRequestMutation();
    const { data: visitsData, isLoading: isVisitsLoading } = useGetAnalyticsInformationVisitsByUserIdQuery(
        { userId: selectedUserId ?? "" },
        { skip: !selectedUserId || !isAdmin }
    );

    const { data: analyticsData, isLoading: isAnalyticsLoading } = useGetAnalyticsInformationAnalyticsQuery(
        { 
            startDate: analyticsStartDate || undefined,
            endDate: analyticsEndDate || undefined
        },
        { skip: !isAdmin }
    );

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [sortField, setSortField] = useState<string>("visits");
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
    const [minVisits, setMinVisits] = useState<number | undefined>();
    const [maxVisits, setMaxVisits] = useState<number | undefined>();
    const [minConversionRate, setMinConversionRate] = useState<number | undefined>();
    const [maxConversionRate, setMaxConversionRate] = useState<number | undefined>();

    const { data: allUsersData, isLoading: isAllUsersLoading } = useGetAnalyticsInformationUsersQuery(
        { 
            startDate: startDate || undefined,
            endDate: endDate || undefined,
            pageNumber: currentPage,
            pageSize: pageSize,
            sortBy: sortField,
            sortDescending: sortOrder === "desc",
            minVisits,
            maxVisits,
            minConversionRate,
            maxConversionRate
        },
        { 
            skip: !isAdmin
        }
    );

    const dispatch = useDispatch();

    const [showUsersList, setShowUsersList] = useState(true);
    const [showSessionsList, setShowSessionsList] = useState(true);

    const [selectedSessionId, setSelectedSessionId] = useState<string | null>(null);
    
    const { data: sessionPositions, isLoading: isPositionsLoading } = useGetAnalyticsInformationVisitsPositionsBySessionIdQuery(
        { sessionId: selectedSessionId ?? "" },
        { skip: !selectedSessionId }
    );

    const [sessionSortField, setSessionSortField] = useState<string>("visitDate");
    const [sessionSortOrder, setSessionSortOrder] = useState<"asc" | "desc">("desc");
    const [sessionMinPositions, setSessionMinPositions] = useState<number | undefined>();
    const [sessionMaxPositions, setSessionMaxPositions] = useState<number | undefined>();
    const [selectedScreenSize, setSelectedScreenSize] = useState<string>("");

    const [selectedUserScreenSize, setSelectedUserScreenSize] = useState<string>("");

    // Initialize MouseTracker
    useEffect(() => {
        mouseTrackerRef.current = new MouseTracker(
            'mouseTracker',
            (animating) => setIsAnimating(animating)
        );

        // Add beforeunload event handler
        const handleBeforeUnload = () => {
            if (!isAdmin && mouseTrackerRef.current) {
                const userId = localStorage.getItem("userId");
                if (userId) {
                    // Send final visit data
                    addVisit({visitInputModel: {
                        userId: userId,
                        sessionId,
                        positions: mouseTrackerRef.current.getPositions(),
                        screenWidth: window.innerWidth,
                        screenHeight: window.innerHeight
                    }});
                    mouseTrackerRef.current.clearTrackingPositions();
                }
            }
        };

        // Add visibilitychange event handler
        const handleVisibilityChange = () => {
            if (document.hidden && !isAdmin && mouseTrackerRef.current) {
                const userId = localStorage.getItem("userId");
                if (userId) {
                    // Send visit data when page becomes hidden
                    addVisit({visitInputModel: {
                        userId: userId,
                        sessionId,
                        positions: mouseTrackerRef.current.getPositions(),
                        screenWidth: window.innerWidth,
                        screenHeight: window.innerHeight
                    }});
                    mouseTrackerRef.current.clearTrackingPositions();
                }
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            if (mouseTrackerRef.current) {
                mouseTrackerRef.current.dispose();
            }
            window.removeEventListener('beforeunload', handleBeforeUnload);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [isAdmin, sessionId, addVisit]);

    useEffect(() => {
        let visitInterval: NodeJS.Timeout | null = null;

        // Check for admin status first
        const params = new URLSearchParams(window.location.search);
        const adminId = params.get('adminId');
        const isAdminUser = adminId === 'ghelfdoiergnjf13125dijhrenf';
        setIsAdmin(isAdminUser);
        if (isAdminUser) {
            setShowVisits(true);
            return; // Don't initialize analytics for admin users
        }

        // Initialize analytics and tracking for non-admin users
        if (mouseTrackerRef.current) {
            const storedUserId = localStorage.getItem("userId");
            const userId = storedUserId || generateUUID();
            
            if (!storedUserId) {
                localStorage.setItem("userId", userId);
            }

            // Initialize analytics only for non-admin users
            clarity.init("pyx5hpengr");
            clarity.identify(userId, sessionId, "Information Page");

            // Initialize Yandex Metrika for non-admin users
            const initYandexMetrika = () => {
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.innerHTML = `
                    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                    m[i].l=1*new Date();
                    k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

                    ym(${YM_COUNTER_ID}, "init", {
                        clickmap: true,
                        trackLinks: true,
                        accurateTrackBounce: true,
                        webvisor: true
                    });
                `;
                document.head.appendChild(script);

                const noscript = document.createElement('noscript');
                const div = document.createElement('div');
                const img = document.createElement('img');
                img.src = `https://mc.yandex.ru/watch/${YM_COUNTER_ID}`;
                img.style.position = "absolute";
                img.style.left = "-9999px";
                img.alt = "";
                div.appendChild(img);
                noscript.appendChild(div);
                document.body.appendChild(noscript);

                // Track initial page view and user ID
                if (window.ym) {
                    window.ym(YM_COUNTER_ID, 'hit', window.location.href);
                    window.ym(YM_COUNTER_ID, 'setUserID', userId);
                }
            };

            initYandexMetrika();

            // Initial visit
            addVisit({visitInputModel: {
                userId: userId,
                sessionId,
                positions: mouseTrackerRef.current.getPositions(),
                screenWidth: window.innerWidth,
                screenHeight: window.innerHeight
            }});
            mouseTrackerRef.current.clearTrackingPositions();
            
            // Set up interval for subsequent visits
            visitInterval = setInterval(() => {
                if (mouseTrackerRef.current) {
                    addVisit({visitInputModel: {
                        userId: userId,
                        sessionId,
                        positions: mouseTrackerRef.current.getPositions(),
                        screenWidth: window.innerWidth,
                        screenHeight: window.innerHeight
                    }});
                    mouseTrackerRef.current.clearTrackingPositions();
                }
            }, 5000);
        }

        return () => {
            if (visitInterval) {
                clearInterval(visitInterval);
            }
            // Cleanup Yandex Metrika noscript element
            const noscriptElements = document.getElementsByTagName('noscript');
            Array.from(noscriptElements).forEach(element => {
                if (element.innerHTML.includes(`mc.yandex.ru/watch/${YM_COUNTER_ID}`)) {
                    element.remove();
                }
            });
        };
    }, [sessionId, addVisit]);

    useEffect(() => {
        if (sessionPositions && mouseTrackerRef.current && !isPositionsLoading) {
            mouseTrackerRef.current.startAnimation(sessionPositions, 1, true);
        }
    }, [sessionPositions, isPositionsLoading]);

    const mouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isAdmin && !showVisits && mouseTrackerRef.current) {
            lastMousePos.current = {
                x: e.clientX,
                y: e.clientY
            };
            mouseTrackerRef.current.addPosition(
                e.clientX + window.scrollX,
                e.clientY + window.scrollY,
                false
            );
        }
    };

    const handleTouch = (e: React.TouchEvent<HTMLDivElement>) => {
        if (!isAdmin && !showVisits && mouseTrackerRef.current) {
            const touch = e.touches[0];
            if (!touch) return;

            // Prevent default to avoid scrolling while tracking
            if (e.type === 'touchstart') {
                e.preventDefault();
            }

            lastMousePos.current = {
                x: touch.clientX,
                y: touch.clientY
            };
            mouseTrackerRef.current.addPosition(
                touch.clientX + window.scrollX,
                touch.clientY + window.scrollY,
                true
            );
        }
    };

    // Add scroll event listener
    useEffect(() => {
        const handleScroll = () => {
            if (!isAdmin && !showVisits && lastMousePos.current && mouseTrackerRef.current) {
                mouseTrackerRef.current.addPosition(
                    lastMousePos.current.x + window.scrollX,
                    lastMousePos.current.y + window.scrollY,
                    false
                );
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isAdmin, showVisits]);

    const trackYandexEvent = (goal: string, params?: any) => {
        if (!isAdmin && window.ym) {
            window.ym(YM_COUNTER_ID, 'reachGoal', goal, params);
        }
    };

    const sendFormData = () => {
        let userId = localStorage.getItem("userId");

        if (!number.trim()) {
            const alert = {
                type: AlertType.Error,
                message: 'Поле "телефон/телеграм" обязательно для заполнения',
                title: '',
                identifier: Math.random().toString(),
                duration: 7000
            };
            dispatch(setAlert(alert));
            
            // Track form error
            trackYandexEvent('form_error', { error: 'missing_phone' });
            return;
        }

        const alert = {
            type: AlertType.Success,
            message: 'Данные успешно отправлены',
            title: '',
            identifier: Math.random().toString(),
            duration: 8000
        };
        dispatch(setAlert(alert));

        // Track successful form submission
        trackYandexEvent('form_submit', {
            hasName: !!name.trim(),
            userId: userId || "anonymous"
        });

        addRecallRequest({recallRequestInputModel: {
            userId: userId ?? "",
            sessionId,
            name: name,
            contacts: number
        }});

        setIsModalOpen(false);
    };

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
  
    const deadline = "January, 30, 2025";
  
    const getTime = () => {
      const time = Date.parse(deadline) - Date.now();
  
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    };
  
    useEffect(() => {
      const interval = setInterval(() => getTime(), 1000);
  
      return () => clearInterval(interval);
    }, [])

    const getWordForm = (number: number, forms: [string, string, string]) => {
        const n = Math.abs(number) % 100;
        const n1 = n % 10;
        if (n > 10 && n < 20) return forms[2];
        if (n1 > 1 && n1 < 5) return forms[1];
        if (n1 === 1) return forms[0];
        return forms[2];
    };

    const [secondarySortField, setSecondarySortField] = useState<string>("lastVisit");

    const handleUserSelect = (userId: string | undefined) => {
        if (userId) {
            setSelectedUserId(userId);
            setSelectedScreenSize(""); // Reset screen size when changing user
        }
    };

    const formatDuration = (seconds: number): string => {
        if (!seconds) return '0s';
        
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.round(seconds % 60);
        
        const parts = [];
        if (hours > 0) parts.push(`${hours}h`);
        if (minutes > 0) parts.push(`${minutes}m`);
        if (remainingSeconds > 0 || parts.length === 0) parts.push(`${remainingSeconds}s`);
        
        return parts.join(' ');
    };

    const calculateEngagementScore = (user: UserAnalytics) => {
        const visitScore = user.totalVisits || 0;
        const durationScore = (user.averageVisitDuration || 0) / 60; // Convert to minutes
        const interactionScore = user.averageInteractionsPerVisit || 0;
        
        // Weighted combination of normalized scores
        return (visitScore * 0.4) + (durationScore * 0.3) + (interactionScore * 0.3);
    };

    const formatInteractionRate = (user: UserAnalytics): string => {
        if (!user.averageVisitDuration || user.averageVisitDuration === 0) return '0';
        
        const rate = (user.averageInteractionsPerVisit || 0) / (user.averageVisitDuration / 60);
        return rate.toFixed(1);
    };

    // Get unique screen sizes from visits data
    const getUniqueScreenSizes = () => {
        if (!visitsData) return [];
        const sizes = new Set(visitsData.map(visit => `${visit.screenWidth}x${visit.screenHeight}`));
        return Array.from(sizes).sort((a, b) => {
            const [aWidth] = a.split('x').map(Number);
            const [bWidth] = b.split('x').map(Number);
            return aWidth - bWidth;
        });
    };

    useEffect(() => {
        // Reset screen size when visits data changes
        setSelectedScreenSize("");
    }, [visitsData]);

    // Get unique screen sizes from user data
    const getUserScreenSizes = () => {
        if (!allUsersData?.items) return [];
        const sizes = new Set(allUsersData.items.flatMap(user => 
            user.usedScreenResolutions?.map(res => `${res.width}x${res.height}`) || []
        ));
        return Array.from(sizes).sort((a, b) => {
            const [aWidth] = a.split('x').map(Number);
            const [bWidth] = b.split('x').map(Number);
            return aWidth - bWidth;
        });
    };

    useEffect(() => {
        // Reset screen size when users data changes
        setSelectedUserScreenSize("");
    }, [allUsersData]);

    return (
        <>
        <div 
            id="mouseTracker" 
            className={styles.pc} 
            onMouseMove={mouseMove}
            onTouchStart={handleTouch}
            onTouchMove={handleTouch}
            style={{overflow: "hidden"}}
        >
            {isAdmin && showVisits && (
                <div style={{
                    position: 'fixed',
                    top: 20,
                    right: 20,
                    left: window.innerWidth <= 768 
                        ? 20 
                        : isAnimating 
                            ? 'unset'
                            : showAnalytics ? 20 : 'unset',
                    background: 'white',
                    padding: isAnimating ? "0" : '15px',
                    borderRadius: 8,
                    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    zIndex: 10000,
                    width: window.innerWidth <= 768 
                        ? 'calc(100% - 40px)' 
                        : isAnimating ? '300px' : 'auto',
                    maxHeight: window.innerWidth <= 768 ? 'calc(100vh - 40px)' : 'auto',
                    overflowY: 'auto',
                    transition: 'all 0.3s ease'
                }}>
                    {!isAnimating && (
                        <>
                            <div style={{ 
                                marginBottom: '15px',
                                // position: 'sticky',
                                top: 0,
                                background: 'white',
                                paddingBottom: '10px',
                                borderBottom: '1px solid #eee',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px'
                            }}>
                                <label style={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    gap: '8px', 
                                    cursor: 'pointer',
                                    fontSize: window.innerWidth <= 768 ? '14px' : '16px'
                                }}>
                                    <input
                                        type="checkbox"
                                        checked={showAnalytics}
                                        onChange={(e) => setShowAnalytics(e.target.checked)}
                                    />
                                    Show Analytics Overview
                                </label>
                                {showAnalytics && (
                                    <div style={{
                                        display: 'flex',
                                        gap: '10px',
                                        flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
                                        alignItems: window.innerWidth <= 768 ? 'stretch' : 'center',
                                        marginBottom: '10px'
                                    }}>
                                        <Input
                                            type="date"
                                            value={analyticsStartDate}
                                            onChange={(e) => setAnalyticsStartDate(e.target.value)}
                                            placeholder="Start Date"
                                            classNamesContainer={styles.searchInput}
                                        />
                                        <Input
                                            type="date"
                                            value={analyticsEndDate}
                                            onChange={(e) => setAnalyticsEndDate(e.target.value)}
                                            placeholder="End Date"
                                            classNamesContainer={styles.searchInput}
                                        />
                                    </div>
                                )}
                            </div>

                            {showAnalytics && (
                                <div style={{
                                    marginBottom: '20px',
                                    padding: isAnalyticsLoading ? "none" : '10px',
                                    background: '#f8f9fa',
                                    borderRadius: '6px',
                                    minHeight: '100px',
                                    position: 'relative'
                                }}>
                                    {isAnalyticsLoading ? (
                                        <Loader />
                                    ) : analyticsData && (
                                        <AnalyticsOverview analyticsData={analyticsData} />
                                    )}
                                </div>
                            )}

                            <h3 style={{ 
                                fontSize: window.innerWidth <= 768 ? '16px' : '18px',
                                marginBottom: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                                userSelect: 'none'
                            }} onClick={() => setShowUsersList(!showUsersList)}>
                                <span>Users Analytics</span>
                                <span style={{ fontSize: '14px' }}>{showUsersList ? '▼' : '▶'}</span>
                            </h3>

                            {showUsersList && (
                                <>
                                    <div style={{
                                        marginBottom: '15px',
                                        display: 'flex',
                                        gap: '10px',
                                        flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
                                        alignItems: window.innerWidth <= 768 ? 'stretch' : 'center'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            gap: '10px',
                                            flexWrap: window.innerWidth <= 768 ? 'wrap' : 'nowrap',
                                            alignItems: 'center'
                                        }}>
                                            <Input
                                                type="date"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                                placeholder="Start Date"
                                                classNamesContainer={styles.searchInput}
                                            />
                                            <Input
                                                type="date"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                                placeholder="End Date"
                                                classNamesContainer={styles.searchInput}
                                            />
                                        </div>
                                        <input
                                            type="text"
                                            placeholder="Search users..."
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            style={{
                                                padding: '8px 12px',
                                                border: '1px solid #ddd',
                                                borderRadius: 4,
                                                flex: 1
                                            }}
                                        />
                                        <div style={{
                                            display: 'flex',
                                            gap: '10px',
                                            flexWrap: window.innerWidth <= 768 ? 'wrap' : 'nowrap',
                                            alignItems: 'center'
                                        }}>
                                            <input
                                                type="number"
                                                placeholder="Min Visits"
                                                value={minVisits || ''}
                                                onChange={(e) => setMinVisits(e.target.value ? Number(e.target.value) : undefined)}
                                                style={{
                                                    padding: '8px 12px',
                                                    border: '1px solid #ddd',
                                                    borderRadius: 4,
                                                    width: '100px'
                                                }}
                                            />
                                            <input
                                                type="number"
                                                placeholder="Max Visits"
                                                value={maxVisits || ''}
                                                onChange={(e) => setMaxVisits(e.target.value ? Number(e.target.value) : undefined)}
                                                style={{
                                                    padding: '8px 12px',
                                                    border: '1px solid #ddd',
                                                    borderRadius: 4,
                                                    width: '100px'
                                                }}
                                            />
                                            <input
                                                type="number"
                                                placeholder="Min Conv. Rate"
                                                value={minConversionRate || ''}
                                                onChange={(e) => setMinConversionRate(e.target.value ? Number(e.target.value) : undefined)}
                                                style={{
                                                    padding: '8px 12px',
                                                    border: '1px solid #ddd',
                                                    borderRadius: 4,
                                                    width: '120px'
                                                }}
                                                step="0.01"
                                                min="0"
                                                max="1"
                                            />
                                            <input
                                                type="number"
                                                placeholder="Max Conv. Rate"
                                                value={maxConversionRate || ''}
                                                onChange={(e) => setMaxConversionRate(e.target.value ? Number(e.target.value) : undefined)}
                                                style={{
                                                    padding: '8px 12px',
                                                    border: '1px solid #ddd',
                                                    borderRadius: 4,
                                                    width: '120px'
                                                }}
                                                step="0.01"
                                                min="0"
                                                max="1"
                                            />
                                        </div>
                                        <div style={{
                                            display: 'flex',
                                            gap: '10px',
                                            flexWrap: window.innerWidth <= 768 ? 'wrap' : 'nowrap',
                                            alignItems: 'center'
                                        }}>
                                            <select
                                                value={selectedUserScreenSize}
                                                onChange={(e) => setSelectedUserScreenSize(e.target.value)}
                                                style={{
                                                    padding: '8px 12px',
                                                    border: '1px solid #ddd',
                                                    borderRadius: 4,
                                                    minWidth: '150px'
                                                }}
                                            >
                                                <option value="">All Screen Sizes</option>
                                                {getUserScreenSizes().map((size) => (
                                                    <option key={size} value={size}>{size}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <select
                                            value={sortField}
                                            onChange={(e) => setSortField(e.target.value)}
                                            style={{
                                                padding: '8px 12px',
                                                border: '1px solid #ddd',
                                                borderRadius: 4,
                                                minWidth: '120px'
                                            }}
                                        >
                                            <option value="visits">Total Visits</option>
                                            <option value="sessions">Total Sessions</option>
                                            <option value="lastVisit">Last Visit</option>
                                            <option value="avgduration">Avg Duration</option>
                                            <option value="totalDuration">Total Duration</option>
                                            <option value="totalPositions">Total Positions</option>
                                            <option value="interactionspervisit">Interactions</option>
                                            <option value="interactionRate">Interaction Rate</option>
                                            <option value="engagementScore">Engagement Score</option>
                                        </select>
                                        <Button
                                            text={sortOrder === "asc" ? "↑" : "↓"}
                                            onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
                                            inlineStyles={{
                                                padding: '8px 12px',
                                                minWidth: '40px'
                                            }}
                                        />
                                    </div>
                                    <div style={{ 
                                        maxHeight: window.innerWidth <= 768 ? 'calc(100vh - 200px)' : '400px',
                                        overflowY: 'auto',
                                        padding: '5px',
                                        minHeight: '100px',
                                        position: 'relative',
                                        marginBottom: '20px'
                                    }}>
                                        {isAllUsersLoading ? (
                                            <div style={{ textAlign: 'center', padding: '20px' }}>Loading...</div>
                                        ) : !allUsersData?.items?.length ? (
                                            <div style={{ textAlign: 'center', padding: '20px' }}>No users found</div>
                                        ) : (
                                            <>
                                                {allUsersData.items.map((user, index) => {
                                                    // Skip users that don't match the screen size filter
                                                    if (selectedUserScreenSize && !user.usedScreenResolutions?.some(
                                                        res => `${res.width}x${res.height}` === selectedUserScreenSize
                                                    )) {
                                                        return null;
                                                    }

                                                    return (
                                                        <div 
                                                            key={index} 
                                                            style={{
                                                                marginBottom: 10,
                                                                padding: '12px',
                                                                border: '1px solid #eee',
                                                                borderRadius: 4,
                                                                fontSize: window.innerWidth <= 768 ? '14px' : '16px',
                                                                cursor: 'pointer',
                                                                background: selectedUserId === (user.userId || '') ? '#f0f7ff' : '#fff'
                                                            }}
                                                            onClick={() => handleUserSelect(user.userId)}
                                                        >
                                                            <div style={{ fontWeight: 'bold' }}>User ID: {user.userId || 'Unknown'}</div>
                                                            <div style={{ marginTop: 5, color: '#666' }}>
                                                                <div>Visits: {user.totalVisits || 0}</div>
                                                                <div>Sessions: {user.sessionsCount || 0}</div>
                                                                <div>Last Visit: {user.lastVisitDate ? new Date(user.lastVisitDate).toLocaleString() : 'Never'}</div>
                                                                <div>Avg Duration: {formatDuration(user.averageVisitDuration || 0)}</div>
                                                                <div>Total Duration: {formatDuration(user.totalDuration || 0)}</div>
                                                                <div>Total Positions: {user.totalPositions || 0}</div>
                                                                <div>Interactions/Visit: {user.averageInteractionsPerVisit?.toFixed(1) || '0'}</div>
                                                                <div>Interaction Rate: {formatInteractionRate(user)} per minute</div>
                                                                <div>Engagement Score: {calculateEngagementScore(user).toFixed(1)}</div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                {/* Pagination controls */}
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    marginTop: '20px',
                                                    padding: '10px',
                                                    borderTop: '1px solid #eee'
                                                }}>
                                                    <div style={{ fontSize: '14px', color: '#666' }}>
                                                        Page {currentPage} of {allUsersData.totalPages || 1}
                                                    </div>
                                                    <div style={{ display: 'flex', gap: '10px' }}>
                                                        <Button
                                                            text="Previous"
                                                            onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                                                            inlineStyles={{
                                                                padding: '6px 12px',
                                                                opacity: currentPage === 1 ? 0.5 : 1
                                                            }}
                                                            disabled={currentPage === 1}
                                                        />
                                                        <Button
                                                            text="Next"
                                                            onClick={() => setCurrentPage(prev => prev + 1)}
                                                            inlineStyles={{
                                                                padding: '6px 12px',
                                                                opacity: !allUsersData.hasNextPage ? 0.5 : 1
                                                            }}
                                                            disabled={!allUsersData.hasNextPage}
                                                        />
                                                    </div>
                                                    <select
                                                        value={pageSize}
                                                        onChange={(e) => {
                                                            setPageSize(Number(e.target.value));
                                                            setCurrentPage(1); // Reset to first page when changing page size
                                                        }}
                                                        style={{
                                                            padding: '6px 12px',
                                                            border: '1px solid #ddd',
                                                            borderRadius: 4
                                                        }}
                                                    >
                                                        <option value="5">5 per page</option>
                                                        <option value="10">10 per page</option>
                                                        <option value="20">20 per page</option>
                                                        <option value="50">50 per page</option>
                                                    </select>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </>
                            )}

                            {selectedUserId && visitsData && (
                                <>
                                    <h3 style={{ 
                                        fontSize: window.innerWidth <= 768 ? '16px' : '18px',
                                        margin: '20px 0 15px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        cursor: 'pointer',
                                        userSelect: 'none'
                                    }} onClick={() => setShowSessionsList(!showSessionsList)}>
                                        <span>User Sessions</span>
                                        <span style={{ fontSize: '14px' }}>{showSessionsList ? '▼' : '▶'}</span>
                                    </h3>
                                    {showSessionsList && (
                                        <>
                                            <div style={{
                                                marginBottom: '15px',
                                                display: 'flex',
                                                gap: '10px',
                                                flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
                                                alignItems: window.innerWidth <= 768 ? 'stretch' : 'center'
                                            }}>
                                                <div style={{
                                                    display: 'flex',
                                                    gap: '10px',
                                                    flexWrap: window.innerWidth <= 768 ? 'wrap' : 'nowrap',
                                                    alignItems: 'center'
                                                }}>
                                                    <input
                                                        type="number"
                                                        placeholder="Min Positions"
                                                        value={sessionMinPositions || ''}
                                                        onChange={(e) => setSessionMinPositions(e.target.value ? Number(e.target.value) : undefined)}
                                                        style={{
                                                            padding: '8px 12px',
                                                            border: '1px solid #ddd',
                                                            borderRadius: 4,
                                                            width: '120px'
                                                        }}
                                                    />
                                                    <input
                                                        type="number"
                                                        placeholder="Max Positions"
                                                        value={sessionMaxPositions || ''}
                                                        onChange={(e) => setSessionMaxPositions(e.target.value ? Number(e.target.value) : undefined)}
                                                        style={{
                                                            padding: '8px 12px',
                                                            border: '1px solid #ddd',
                                                            borderRadius: 4,
                                                            width: '120px'
                                                        }}
                                                    />
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    gap: '10px',
                                                    flexWrap: window.innerWidth <= 768 ? 'wrap' : 'nowrap',
                                                    alignItems: 'center'
                                                }}>
                                                    <select
                                                        value={selectedScreenSize}
                                                        onChange={(e) => setSelectedScreenSize(e.target.value)}
                                                        style={{
                                                            padding: '8px 12px',
                                                            border: '1px solid #ddd',
                                                            borderRadius: 4,
                                                            minWidth: '150px'
                                                        }}
                                                    >
                                                        <option value="">All Screen Sizes</option>
                                                        {getUniqueScreenSizes().map((size) => (
                                                            <option key={size} value={size}>{size}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <select
                                                    value={sessionSortField}
                                                    onChange={(e) => setSessionSortField(e.target.value)}
                                                    style={{
                                                        padding: '8px 12px',
                                                        border: '1px solid #ddd',
                                                        borderRadius: 4,
                                                        minWidth: '120px'
                                                    }}
                                                >
                                                    <option value="visitDate">Visit Date</option>
                                                    <option value="positionsCount">Positions Count</option>
                                                </select>
                                                <Button
                                                    text={sessionSortOrder === "asc" ? "↑" : "↓"}
                                                    onClick={() => setSessionSortOrder(sessionSortOrder === "asc" ? "desc" : "asc")}
                                                    inlineStyles={{
                                                        padding: '8px 12px',
                                                        minWidth: '40px'
                                                    }}
                                                />
                                            </div>
                                            <div style={{ 
                                                maxHeight: window.innerWidth <= 768 ? 'calc(100vh - 200px)' : '400px',
                                                overflowY: 'auto',
                                                padding: '5px'
                                            }}>
                                                {isVisitsLoading ? (
                                                    <Loader />
                                                ) : visitsData
                                                    .filter(visit => {
                                                        if (sessionMinPositions !== undefined && (visit.positionsCount || 0) < sessionMinPositions) return false;
                                                        if (sessionMaxPositions !== undefined && (visit.positionsCount || 0) > sessionMaxPositions) return false;
                                                        if (selectedScreenSize && `${visit.screenWidth}x${visit.screenHeight}` !== selectedScreenSize) return false;
                                                        return true;
                                                    })
                                                    .sort((a, b) => {
                                                        const multiplier = sessionSortOrder === "asc" ? 1 : -1;
                                                        switch (sessionSortField) {
                                                            case "visitDate":
                                                                return multiplier * (new Date(a.visitDate!).getTime() - new Date(b.visitDate!).getTime());
                                                            case "positionsCount":
                                                                return multiplier * ((a.positionsCount || 0) - (b.positionsCount || 0));
                                                            default:
                                                                return 0;
                                                        }
                                                    })
                                                    .map((visit, index) => (
                                                        <div key={index} style={{
                                                            marginBottom: 10,
                                                            padding: '12px',
                                                            border: '1px solid #eee',
                                                            borderRadius: 4,
                                                            fontSize: window.innerWidth <= 768 ? '14px' : '16px'
                                                        }}>
                                                            <div>Session: {visit.sessionId}</div>
                                                            <div>Visit Date: {new Date(visit.visitDate!).toLocaleString()}</div>
                                                            <div>Screen: {visit.screenWidth}x{visit.screenHeight}</div>
                                                            <div>Positions: {visit.positionsCount || 0}</div>
                                                            <Button 
                                                                text={isPositionsLoading ? "Loading..." : "Show Session Points"}
                                                                onClick={() => {
                                                                    if (mouseTrackerRef.current && visit.sessionId) {
                                                                        setSelectedSessionId(visit.sessionId);
                                                                    }
                                                                }}
                                                                disabled={isPositionsLoading}
                                                                inlineStyles={{
                                                                    width: window.innerWidth <= 768 ? '100%' : 'auto',
                                                                    marginTop: '8px',
                                                                    opacity: isPositionsLoading ? 0.7 : 1
                                                                }}
                                                            />
                                                        </div>
                                                    ))}
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {isAnimating && (
                        <div style={{ padding: window.innerWidth <= 768 ? '3px' : '10px' }}>
                            {window.innerWidth <= 768 ? (
                                <div style={{ 
                                    display: 'flex',
                                    gap: '3px',
                                    alignItems: 'center'
                                }}>
                                    <Button 
                                        text="Stop"
                                        onClick={() => {
                                            if (mouseTrackerRef.current) {
                                                mouseTrackerRef.current.stopAnimation();
                                            }
                                        }}
                                        inlineStyles={{
                                            padding: '4px 8px',
                                            fontSize: '11px',
                                            flex: '0 0 auto',
                                            minWidth: '32px'
                                        }}
                                    />
                                    <Button
                                        text={mouseTrackerRef.current?.getAutoScroll() ? "Auto ✓" : "Auto"}
                                        onClick={() => {
                                            if (mouseTrackerRef.current) {
                                                const currentState = mouseTrackerRef.current.getAutoScroll();
                                                mouseTrackerRef.current.setAutoScroll(!currentState);
                                            }
                                        }}
                                        inlineStyles={{
                                            padding: '4px 8px',
                                            opacity: mouseTrackerRef.current?.getAutoScroll() ? 1 : 0.6,
                                            fontSize: '11px',
                                            flex: '0 0 auto',
                                            minWidth: '32px'
                                        }}
                                    />
                                    <div style={{ 
                                        display: 'flex',
                                        gap: '3px',
                                        flex: 1
                                    }}>
                                        {[0.5, 1, 2, 4].map((speed) => (
                                            <Button
                                                key={speed}
                                                text={`${speed}x`}
                                                onClick={() => {
                                                    if (mouseTrackerRef.current) {
                                                        mouseTrackerRef.current.setPlaybackSpeed(speed);
                                                    }
                                                }}
                                                inlineStyles={{
                                                    padding: '4px 12px',
                                                    opacity: mouseTrackerRef.current?.getPlaybackSpeed() === speed ? 1 : 0.6,
                                                    fontSize: '11px',
                                                    flex: 1
                                                }}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Button 
                                        text="Stop" 
                                        onClick={() => {
                                            if (mouseTrackerRef.current) {
                                                mouseTrackerRef.current.stopAnimation();
                                            }
                                        }}
                                        inlineStyles={{
                                            width: 'auto',
                                            marginBottom: '10px',
                                            padding: '10px 20px',
                                            fontSize: '14px'
                                        }}
                                    />
                                    <div style={{ 
                                        marginTop: '10px', 
                                        display: 'flex', 
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}>
                                        <span style={{ fontSize: '16px' }}>Speed:</span>
                                        <div style={{ 
                                            display: 'flex',
                                            gap: '5px'
                                        }}>
                                            {[0.5, 1, 2, 4].map((speed) => (
                                                <Button
                                                    key={speed}
                                                    text={`${speed}x`}
                                                    onClick={() => {
                                                        if (mouseTrackerRef.current) {
                                                            mouseTrackerRef.current.setPlaybackSpeed(speed);
                                                        }
                                                    }}
                                                    inlineStyles={{
                                                        padding: '5px 10px',
                                                        opacity: mouseTrackerRef.current?.getPlaybackSpeed() === speed ? 1 : 0.6,
                                                        fontSize: '14px'
                                                    }}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                    <div style={{ 
                                        marginTop: '10px', 
                                        display: 'flex', 
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}>
                                        <span style={{ fontSize: '16px' }}>Auto-scroll:</span>
                                        <Button
                                            text={mouseTrackerRef.current?.getAutoScroll() ? "On" : "Off"}
                                            onClick={() => {
                                                if (mouseTrackerRef.current) {
                                                    const currentState = mouseTrackerRef.current.getAutoScroll();
                                                    mouseTrackerRef.current.setAutoScroll(!currentState);
                                                }
                                            }}
                                            inlineStyles={{
                                                padding: '5px 10px',
                                                opacity: mouseTrackerRef.current?.getAutoScroll() ? 1 : 0.6,
                                                fontSize: '14px'
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}
            <div className={styles.container}>
                <div className={clsx(styles.header, styles.width)}>
                    <Logo inlineClass={styles.logo} fill="#003366"/>
                    <div className={clsx(styles.header__container, styles.color_white, styles.mobile)}>
                        <div className={styles.header__margin}>
                            <a href="#opportunities">
                                Возможности
                            </a>
                        </div>
                        <div>
                            <a href="#advantages">
                                Преимущества
                            </a>
                        </div>
                        <div className={clsx(styles.button, styles.header__marginLeft)} onClick={() => setIsModalOpen(true)}>
                            Оставить заявку
                        </div>
                    </div>
                </div>
            </div>
            <div className={clsx(styles.container, styles.container_blue)} >
                <div className={clsx(styles.form, styles.width)}>
                    <div className={styles.form__container}>
                        <div className={styles.h2}>
                            Повышайте продуктивность вашего склада совместно с нами!
                        </div>
                        <div className={styles.h2_sub}>
                            Извлеките всю выгоду из современных методов управления складом.
                        </div>
                        <div className={clsx(styles.button, styles.button_white)} onClick={() =>  setIsModalOpen(true)}>
                            Оставить заявку
                        </div>
                    </div>
                    {IS_PC ?
                    <img className={styles.img} src="assets/images/information.png" alt="" />
                    : <img className={styles.img} src="assets/images/information-mobile.png" alt="" />
                }
                </div>
            </div>
            <div className={clsx(styles.container)} id="opportunities">
                <div className={clsx(styles.width, styles.block_2)}>
                    <div className={styles.h3} style={{textAlign:"center"}}>
                        Функциональные возможности
                    </div>
                    <div className={styles.functions}>
                        <div className={styles.function}>
                            <img className={styles.icon} src="assets/images/i1.png" alt="" />
                            <div className={styles.function__text}>
                                Одновременное участие нескольких людей в выполнении одного процесса.
                            </div>
                        </div>
                        <div className={styles.function}>
                            <I4Icon width="80px" height="80px" stroke="#003366"/>
                            <div className={styles.function__text}>
                                Автоматическое распределение товаров по складу.
                            </div>
                        </div>
                        <div className={styles.function}>
                            <img className={styles.icon} src="assets/images/i3.png" alt="" />
                            <div className={styles.function__text}>
                                Выполнение процесса может быть приостановлено и закончено в другое время.
                            </div>
                        </div>
                        <div className={styles.function}>
                            <img className={styles.icon} src="assets/images/i6.png" alt="" />
                            <div className={styles.function__text}>
                                Визуальный конструктор схемы склада. 
                            </div>
                        </div>
                        <div className={styles.function}>
                            <img className={styles.icon} src="assets/images/i5.png" alt="" />
                            <div className={styles.function__text}>
                                Карта, отображающая в реальном времени местоположение объектов или людей.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={clsx(styles.container, styles.container_blue)} style={{backgroundColor: "#F1F4FA"}}>
                <div className={clsx(styles.width, styles.block_4)}>
                    <div className={styles.h3} style={{textAlign:"center", maxWidth: 590}}>
                        Что мы сможем сделать
                        для вашего склада
                    </div>
                    <div className={styles.numbers}>
                        <div className={styles.number__wrapper}>
                            <div className={styles.number__container}>
                                <img src="assets/images/information_number_back.png" alt="" />
                                <div className={styles.number}>
                                    1
                                </div>
                            </div>
                            <div className={styles.number__title}>
                                Снижение необходимости в участии человека на складе.
                            </div>
                            <div className={styles.number_subTitle}>
                                Расположение товара будет отслеживаться программой, 
                                а не кладовщиком. Сканирование во время выполнения 
                                операций будет способствовать обнаружению ошибок и 
                                предотвратит их возникновение.
                            </div>
                        </div>
                        <div className={styles.number__wrapper}>
                            <div className={styles.number__container}>
                                <img src="assets/images/information_number_back.png" alt="" />
                                <div className={styles.number}>
                                    2
                                </div>
                            </div>
                            <div className={styles.number__title}>
                                Рост прибыли.                            
                            </div>
                            <div className={styles.number_subTitle}>
                                Автоматизация и оптимизация процессов позволяет ускорить операции на складе, снижая затраты и повышая общую производительность, что увеличивает прибыль.
                            </div>
                        </div>
                        <div className={styles.number__wrapper}>
                            <div className={styles.number__container}>
                                <img src="assets/images/information_number_back.png" alt="" />
                                <div className={styles.number}>
                                    3
                                </div>
                            </div>
                            <div className={styles.number__title}>
                                Рекомендации.
                            </div>
                            <div className={styles.number_subTitle}>
                                Система рекомендаций, основанная на опыте других лидеров, точках зрения ведущих аналитиков и предложениях ИИ.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.container} style={{backgroundColor: "#fff"}} id="advantages">
                <div className={clsx(styles.width, styles.block_5)}>
                    <div className={styles.h3} style={{textAlign:"center", maxWidth: 590}}>
                        Наши преимущества над
                        конкурентами
                    </div>
                    <div className={styles.pluses}>
                        <div className={styles.plus}>
                            <img src="assets/images/i2.png" alt="" />
                            <div className={styles.plus__text}>
                                Приложение функционирует на новейших интернет-технологиях,
                                отличается быстрым откликом и удобным интерфейсом.
                            </div>
                        </div>
                        <div className={styles.plus}>
                            <img src="assets/images/i9.png" alt="" />
                            <div className={styles.plus__text}>
                                У нас есть функции, которые отсутствуют у 
                                остальных, а те, что имеются, оптимизированы 
                                для более продуктивного использования.
                            </div>
                        </div>
                        <div className={styles.plus}>
                            <img src="assets/images/i8.png" alt="" />
                            <div className={styles.plus__text}>
                                Мы уделяем внимание не только wms системе, 
                                но также активно развиваем робототехнику, 
                                маркетплейс и логистическое предприятие.
                            </div>
                        </div>
                        <div className={styles.plus}>
                            <img src="assets/images/i7.png" alt="" />
                            <div className={styles.plus__text}>
                                Мы молодая команда специалистов и желаем привнести
                                современные инновации, которые поднимут вашу прибыль.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className={styles.container} style={{backgroundColor: "#FFEC1E", position: "relative"}}>
                <div className={clsx(styles.width, styles.block_4, styles.block_action)}>
                    <div className={styles.h3} style={{textAlign:"center", maxWidth: 790, margin: "0 auto"}}>
                        Уникальная акция
                    </div>
                    <div className={styles.action1}>
                        Увеличьте прибыль бесплатно: 30 дней на пробу.
                    </div>
                    <div className={styles.plusIcon}>
                        +
                    </div>
                    <div className={styles.action2}>
                        Если успеете оформить подписку в течение
                    </div>
                    <div className={styles.timer__wrapper}>
                    <div className={styles.timer_part}>
                        <div className={styles.timer__number}>{days}</div>
                            <div className={styles.timer__text}>{getWordForm(days, ['День', 'Дня', 'Дней'])}</div>
                        </div>
                        <div className={styles.timer_part}>
                            <div className={styles.timer__number}>{hours}</div>
                            <div className={styles.timer__text}>{getWordForm(hours, ['Час', 'Часа', 'Часов'])}</div>
                        </div>
                        <div className={styles.timer_part}>
                            <div className={styles.timer__number}>{minutes}</div>
                            <div className={styles.timer__text}>{getWordForm(minutes, ['Минута', 'Минуты', 'Минут'])}</div>
                        </div>
                        <div className={styles.timer__left}>
                            <div className={styles.timer__number}>15%</div>
                            <div className={styles.timer__text}>Получите скидку на следующий месяц.</div>
                        </div>
                        <div className={styles.timer__right}>
                            <div className={styles.timer__number}>40%</div>
                            <div className={styles.timer__text}>Розыгрыш 40% скидки на 3 месяца.</div>
                        </div>
                        <PunctirLineIcon inlineClass={styles.timer__left_line}/>
                        <PunctirLineIcon inlineClass={styles.timer__right_line}/>
                    </div>
                </div>
                <TimerBackIcon inlineClass={styles.timer__back}/>
                <TimerBackIcon inlineClass={styles.timer__back_right}/>
            </div>
            <div className={styles.container} style={{backgroundColor: "#F1F4FA", position: "relative"}}>
                <div className={styles.functions__stick}/>
                <div className={clsx(styles.width, styles.block_4)}>
                    <div className={styles.h3} style={{textAlign:"center", maxWidth: 790}}>
                        Если не хватает существующих 
                        возможностей программы
                    </div>
                    <div className={styles.functions}>

                        <div className={styles.function}>
                            <div className={clsx(styles.number, styles.number_blue)}>
                                1
                            </div>
                            <div className={styles.function__text}>
                                При необходимости можем помочь с проведением анализа.
                            </div>
                        </div>
                        <div className={styles.function}>
                            <div className={clsx(styles.number, styles.number_blue)}>
                                2
                            </div>
                            <div className={styles.function__text}>
                                Выполним необходимые настройки и 
                                доработки программы.
                            </div>
                        </div>
                        <div className={styles.function}>
                            <div className={clsx(styles.number, styles.number_blue)}>
                                3
                            </div>
                            <div className={styles.function__text}>
                                Проведем обучение для пользователей.
                            </div>
                        </div>
                        <div className={styles.function}>
                            <div className={clsx(styles.number, styles.number_blue)}>
                                4
                            </div>
                            <div className={styles.function__text}>
                                Сообщество может дать советы по улучшению доработок.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.container}>
                <div className={clsx(styles.width, styles.block_4)} style={{backgroundColor: "#fff", display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div className={styles.h3} style={{textAlign:"center", maxWidth: 790, margin: "0 auto 0 auto"}}>
                        Остались вопросы?
                    </div>
                    <div className={styles.block_6} style={{fontSize: 20, fontWeight: 500, textAlign:'center'}}>
                        Получите консультацию нашего менеджера
                    </div>
                    <div className={styles.block_7}>
                        <Input inlineStyles={{borderRadius: 0}} classNamesContainer={clsx(styles.fieds_width, styles.fields_width_margin)} value={name} placeholder="Имя" onChange={(e) => setName(e.target.value)}/>
                        <Input inlineStyles={{borderRadius: 0}} classNamesContainer={clsx(styles.fieds_width, styles.fields_width_margin)} value={number} placeholder="телефон/телеграм" onChange={(e) =>  setNumber(e.target.value)}/>
                        <Button className={styles.fieds_width} inlineStyles={{justifyContent: "center", borderRadius: 0}} text="Получить консультацию" onClick={sendFormData}/>
                    </div>
                </div>
            </div>
            <div className={clsx(styles.footer)}>
                <div className={styles.container}>
                    <div className={clsx(styles.footer__top, styles.width)}>
                        <div className={styles.footer__logoContainer}>
                            <Logo inlineClass={styles.logo} fill="#fff" inlineStyles={{marginRight: "40px"}}/>
                            <div onClick={() => setIsPoliticModalOpen(true)}>
                                <a href="#" style={{color: "#fff"}}>Политика конфиденциальности</a>
                            </div>
                        </div>
                        <div className={clsx(styles.footer__stick, styles.width, styles.block_8)}/>
                        <div className={styles.contacts}>
                            <a style={{marginRight: 10}} href="https://t.me/difwmsru" target="_blank">
                                Телеграм: @difwms
                            </a>
                            <a style={{marginRight: 10}} href="tel:+79196165333">
                                +7 (919) 616-53-33
                            </a>
                            <div>
                                diffwms@gmail.com
                            </div>
                        </div>
                    </div>
                    <div className={clsx(styles.footer__stick, styles.width)}/>
                    <div className={styles.footer__bottom}>
                        2024 © Все права защищены.
                    </div>
                </div>
            </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                close={() => setIsModalOpen(false)}
                inlineStyles={{maxWidth: "97vw"}}
                >
                    <div
                        style={{display: "flex", flexDirection: "column", alignItems: "center"}}
                        >
                        <Logo fill="#003366" inlineClass={styles.logo}/>
                        <div className={styles.modal__title}>Нужна консультация ?</div>
                        <div className={styles.modal__subTitle}>Оставьте свой номер, и мы перезвоним вам</div>
                        <Input inlineStyles={{borderRadius: 0}} classNamesContainer={styles.modal__fiedsWidth} value={name} placeholder="Имя" onChange={(e) => setName(e.target.value)}/>
                        <Input inlineStyles={{borderRadius: 0}} classNamesContainer={styles.modal__fiedsWidth} value={number} placeholder="телефон/телеграм" onChange={(e) =>  setNumber(e.target.value)}/>
                        <Button className={styles.modal__fiedsWidth} inlineStyles={{justifyContent: "center", borderRadius: 0}} text="Получить консультацию" onClick={sendFormData}/>
                    </div>
            </Modal>
            <Modal
                isOpen={isPoliticModalOpen}
                classNames={styles.politicModal}
                close={() => setIsPoliticModalOpen(false)}
            >
                <div className={styles.politic}>
                
                <header>
                    <h1>Политика конфиденциальности</h1>
                </header>
            
                <section>
                    <p>Настоящая Политика конфиденциальности описывает порядок сбора, использования и защиты вашей информации в рамках использования нашей системы управления складом DIF.</p>
            
                    <h2>1. Сбор информации</h2>
                    <p>Мы можем собирать следующую информацию о вас:</p>
                    <ul>
                        <li><strong>Личная информация:</strong> Имя, адрес электронной почты, номер телефона и другие контактные данные.</li>
                        <li><strong>Информация об использовании:</strong> Данные о вашем взаимодействии с системой, включая логины, действия в системе и IP-адреса.</li>
                        <li><strong>Информация о компании:</strong> Название вашего бизнеса и другие относящиеся к делу детали, которые вы предоставляете нам.</li>
                    </ul>
            
                    <h2>2. Использование информации</h2>
                    <p>Мы используем собранную информацию для:</p>
                    <ul>
                        <li>Обеспечения доступа и использования нашей WMS-системы.</li>
                        <li>Улучшения наших услуг и пользовательского опыта.</li>
                        <li>Коммуникации с вами, включая рассылку обновлений и поддерживающих уведомлений.</li>
                        <li>Соблюдения правовых и регуляторных требований.</li>
                    </ul>
            
                    <h2>3. Обмен и раскрытие информации</h2>
                    <p>Мы не будем передавать вашу личную информацию третьим лицам, за исключением следующих случаев:</p>
                    <ul>
                        <li>С вашего согласия.</li>
                        <li>Для соблюдения законов и нормативных требований.</li>
                        <li>В случае продажи или покупки бизнеса, в рамках которого ваши данные могут быть переданы новому владельцу.</li>
                    </ul>
            
                    <h2>4. Защита информации</h2>
                    <p>Мы принимаем коммерчески обоснованные меры для защиты вашей информации от несанкционированного доступа, использования или раскрытия. Однако ни один метод передачи данных через интернет или метод хранения информации не является полностью безопасным, поэтому мы не можем гарантировать абсолютную безопасность.</p>
            
                    <h2>5. Cookies и аналогичные технологии</h2>
                    <p>Мы можем использовать cookies и аналогичные технологии для улучшения вашего опыта пользования и анализа использования нашей системы.</p>
            
                    <h2>6. Ваши права</h2>
                    <p>Вы имеете право запрашивать доступ, исправление или удаление вашей личной информации. Для этого, пожалуйста, свяжитесь с нами по контактной информации, указанной ниже.</p>
            
                    <h2>7. Изменения в Политике конфиденциальности</h2>
                    <p>Мы оставляем за собой право вносить изменения в настоящую Политику конфиденциальности. Мы уведомим вас о любых изменениях, опубликовав новую версию на нашем сайте и указав дату ее вступления в силу.</p>
            
                    <h2>8. Контактная информация</h2>
                    <p>Если у вас есть вопросы или замечания по поводу данной Политики конфиденциальности, пожалуйста, свяжитесь с нами:</p>
                    <p>Email: <a href="mailto:darkhaker03@gmail.com">darkhaker03@gmail.com</a>
                    Телефон: <a href="tel:+79196165333">89196165333</a></p>
                </section>
                </div>
            </Modal>
        </>
    );
};

export {
    InformationPage
};