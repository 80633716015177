import { createBrowserRouter, Navigate, redirect, RouteObject, RouterProvider } from 'react-router-dom';
import { lazy, Suspense, startTransition } from 'react';
import { LinkPagesEnum } from 'app/store/enums/linkPages';
import { Loader } from 'shared/ui/Loader';
import { InformationPage } from './Information';

// Convert all imports to lazy imports with named exports
const RegistrationPage = lazy(() => import('./Registration/ui').then(module => ({ default: module.RegistrationPage })));
const AuthorizationPage = lazy(() => import('./Authorization/ui').then(module => ({ default: module.AuthorizationPage })));
const HomePage = lazy(() => import('./Home/ui').then(module => ({ default: module.HomePage })));
const DocumentsPage = lazy(() => import('./Documents/ui').then(module => ({ default: module.DocumentsPage })));
const AcceptanceBillsPage = lazy(() => import('./AcceptanceBills/ui').then(module => ({ default: module.AcceptanceBillsPage })));
const AddingAcceptanceBillPage = lazy(() => import('./AddingAcceptanceBill/ui').then(module => ({ default: module.AddingAcceptanceBillPage })));
const ProductsPage = lazy(() => import('./Products/ui').then(module => ({ default: module.ProductsPage })));
const InventoryPage = lazy(() => import('./Inventory/ui').then(module => ({ default: module.InventoryPage })));
const CellsPage = lazy(() => import('./Cells/ui').then(module => ({ default: module.CellsPage })));
const TerminalPage = lazy(() => import('./Terminal/ui/Terminal').then(module => ({ default: module.TerminalPage })));
const InspectionPage = lazy(() => import('./Inspection/ui').then(module => ({ default: module.InspectionPage })));
const ShipmentBillsPage = lazy(() => import('./ShipmentBills').then(module => ({ default: module.ShipmentBillsPage })));
const SettingsPage = lazy(() => import('./Settings/ui/Settings').then(module => ({ default: module.SettingsPage })));
const UserProfilePage = lazy(() => import('./Profile').then(module => ({ default: module.UserProfilePage })));
const PlacementPage = lazy(() => import('./Placement').then(module => ({ default: module.PlacementPage })));
const PlacementProcessPage = lazy(() => import('./Placement/pages/Process').then(module => ({ default: module.PlacementProcessPage })));
const AddingShipmentBillPage = lazy(() => import('./AddingShipmentBill').then(module => ({ default: module.AddingShipmentBillPage })));
const RedactShipmentBillPage = lazy(() => import('./RedactShipmentBill/ui').then(module => ({ default: module.RedactShipmentBillPage })));
const ShipPage = lazy(() => import('./Ship').then(module => ({ default: module.ShipPage })));
const EmployeesPage = lazy(() => import('./Employees').then(module => ({ default: module.EmployeesPage })));
const UsersPage = lazy(() => import('./Users').then(module => ({ default: module.UsersPage })));
const SoonPage = lazy(() => import('./Soon').then(module => ({ default: module.SoonPage })));
// const InformationPage = lazy(() => import('./Information').then(module => ({ default: module.InformationPage })));
const ProcessesPage = lazy(() => import('./Processes/ui').then(module => ({ default: module.ProcessesPage })));
const ShipProcessPage = lazy(() => import('./Ship/pages/Process').then(module => ({ default: module.ShipProcessPage })));
const InspectionProcessPage = lazy(() => import('./Inspection/pages/Process').then(module => ({ default: module.InspectionProcessPage })));
const WarehousesPage = lazy(() => import('./Warehouses').then(module => ({ default: module.WarehousesPage })));
const WarehousePage = lazy(() => import('./Warehouse').then(module => ({ default: module.WarehousePage })));
const PriceManagementPage = lazy(() => import('./PriceManagement').then(module => ({ default: module.PriceManagementPage })));
const RedactProductPage = lazy(() => import('./RedactProduct').then(module => ({ default: module.RedactProductPage })));
const UserTypeSelectPage = lazy(() => import('./UserTypeSelect').then(module => ({ default: module.UserTypeSelectPage })));
const RedactPackageBillPage = lazy(() => import('./RedactPackageBill').then(module => ({ default: module.RedactPackageBillPage })));
const WarehouseSchemePage = lazy(() => import('./WarehouseScheme').then(module => ({ default: module.WarehouseSchemePage })));
const WarehouseDesignerPage = lazy(() => import('./WarehouseDesigner').then(module => ({ default: module.WarehouseDesignerPage })));
const SupportPage = lazy(() => import('./Support').then(module => ({ default: module.SupportPage })));
const UpdateWarehousePage = lazy(() => import('./UpdateWarehouse').then(module => ({ default: module.UpdateWarehousePage })));
const InformationBasePage = lazy(() => import('./InformationBase').then(module => ({ default: module.InformationBasePage })));
const AddingProductPage = lazy(() => import('./AddingProduct').then(module => ({ default: module.AddingProductPage })));
const PlacementBillPage = lazy(() => import('./PlacementBill').then(module => ({ default: module.PlacementBillPage })));
const CalendarPage = lazy(() => import('./Calendar').then(module => ({ default: module.CalendarPage })));
const ChartsPage = lazy(() => import('./Charts').then(module => ({ default: module.ChartsPage })));
const IntegrationsPage = lazy(() => import('./Integrations').then(module => ({ default: module.IntegrationsPage })));
const ConsumablesPage = lazy(() => import('./Consumables').then(module => ({ default: module.ConsumablesPage })));
const OrganizationsPage = lazy(() => import('./Organizations').then(module => ({ default: module.OrganizationsPage })));
const CooperationsPage = lazy(() => import('./Cooperations').then(module => ({ default: module.CooperationsPage })));
const PlacementBillsPage = lazy(() => import('./PlacementBills').then(module => ({ default: module.PlacementBillsPage })));
const PackageBillsPage = lazy(() => import('./PackageBills').then(module => ({ default: module.PackageBillsPage })));
const AddingPackageBillPage = lazy(() => import('./AddingPackageBill').then(module => ({ default: module.AddingPackageBillPage })));
const AddingKitPage = lazy(() => import('./AddingKit').then(module => ({ default: module.AddingKitPage })));
const PaymentBillPage = lazy(() => import('./PaymentBill').then(module => ({ default: module.PaymentBillPage })));
const PaymentBillsPage = lazy(() => import('./PaymentBills').then(module => ({ default: module.PaymentBillsPage })));
const SupportChatPage = lazy(() => import('./Support/Chat').then(module => ({ default: module.SupportChatPage })));
const PackagePage = lazy(() => import('./Package').then(module => ({ default: module.PackagePage })));
const PackageProcessPage = lazy(() => import('./Package/pages/Process').then(module => ({ default: module.PackageProcessPage })));
const RedactAcceptanceBillPage = lazy(() => import('./RedactAcceptanceBill/ui').then(module => ({ default: module.RedactAcceptanceBillPage })));
const WarehouseRatingPage = lazy(() => import('./WarehouseRating').then(module => ({ default: module.WarehouseRatingPage })));
const CreateTicketPage = lazy(() => import('./CreateTicket').then(module => ({ default: module.CreateTicketPage })));

type RouteType = {
  pageName: string;
  routeObject: RouteObject
};

// Create a wrapper component for Suspense
const SuspenseWrapper = ({ children }: { children: React.ReactNode }) => (
  <Suspense fallback={<Loader/>}>
    {children}
  </Suspense>
);

export const routes: RouteType[] = [
  {
    pageName: "Управление ценами",
    routeObject: {
      path: LinkPagesEnum.PriceManagement,
      element: <SuspenseWrapper><PriceManagementPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Склад",
    routeObject: {
      path: LinkPagesEnum.Warehouse,
      element: <SuspenseWrapper><WarehousePage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Склады",
    routeObject: {
      path: LinkPagesEnum.Warehouses,
      element: <SuspenseWrapper><WarehousesPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Чат",
    routeObject: {
      path: LinkPagesEnum.SupportChat,
      element: <SuspenseWrapper><SupportChatPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Выбор типа аккаунта",
    routeObject: {
      path: LinkPagesEnum.UserTypeSelect,
      element: <SuspenseWrapper><UserTypeSelectPage/></SuspenseWrapper>,
    }
  },
  
  {
    pageName: "Накладная на упаковку",
    routeObject: {
      path: LinkPagesEnum.RedactPackageBill,
      element: <SuspenseWrapper><RedactPackageBillPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "",
    routeObject: {
      path: "*",
      element: <Navigate to={LinkPagesEnum.Home}  replace/>,
    }
  },
  {
    pageName: "Склад",
    routeObject: {
      path: LinkPagesEnum.WarehouseScheme,
      element: <SuspenseWrapper><WarehouseSchemePage/></SuspenseWrapper>,
    }
  },
  {
    pageName: "Конструктор",
    routeObject: {
      path: LinkPagesEnum.WarehouseDesigner,
      element: <SuspenseWrapper><WarehouseDesignerPage/></SuspenseWrapper>,
    }
  },
  {
    pageName: "Поддержка",
    routeObject: {
      path: LinkPagesEnum.Support,
      element: <SuspenseWrapper><SupportPage/></SuspenseWrapper>,
    }
  },
  {
    pageName: "Создание склада",
    routeObject: {
      path: LinkPagesEnum.AddingWarehouse,
      element: <SuspenseWrapper><UpdateWarehousePage/></SuspenseWrapper>,
    }
  },
  {
    pageName: "Редактирование склада",
    routeObject: {
      path: LinkPagesEnum.RedactWarehouse,
      element: <SuspenseWrapper><UpdateWarehousePage/></SuspenseWrapper>,
    }
  },
  {
    pageName: "База информации",
    routeObject: {
      path: LinkPagesEnum.InformationBase,
      element: <SuspenseWrapper><InformationBasePage/></SuspenseWrapper>,
    }
  },
  {
    pageName: "Редактирование товара",
    routeObject: {
      path: LinkPagesEnum.RedactProduct,
      element: <SuspenseWrapper><RedactProductPage/></SuspenseWrapper>,
    }
  },
  {
    pageName: "Создание товара",
    routeObject: {
      path: LinkPagesEnum.AddingProduct,
      element: <SuspenseWrapper><AddingProductPage/></SuspenseWrapper>,
    }
  },
  {
    pageName: "Накладная на перемещение",
    routeObject: {
      path: LinkPagesEnum.RedactPlacementBill,
      element: <SuspenseWrapper><PlacementBillPage/></SuspenseWrapper>,
    }
  },
  {
    pageName: "Календарь",
    routeObject: {
      path: LinkPagesEnum.Calender,
      element: <SuspenseWrapper><CalendarPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Аналитика",
    routeObject: {
      path: LinkPagesEnum.Analytics,
      element: <SuspenseWrapper><ChartsPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Интеграции",
    routeObject: {
      path: LinkPagesEnum.Integrations,
      element: <SuspenseWrapper><IntegrationsPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Расходники",
    routeObject: {
      path: LinkPagesEnum.Consumables,
      element: <SuspenseWrapper><ConsumablesPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Организации",
    routeObject: {
      path: LinkPagesEnum.Organizations,
      element: <SuspenseWrapper><OrganizationsPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Сотрудничество",
    routeObject: {
      path: LinkPagesEnum.Cooperations,
      element: <SuspenseWrapper><CooperationsPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Транзитные накладные",
    routeObject: {
      path: LinkPagesEnum.PlacementBills,
      element: <SuspenseWrapper><PlacementBillsPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Создание накладной перемещения",
    routeObject: {
      path: LinkPagesEnum.AddingPlacementBill,
      element: <SuspenseWrapper><PlacementBillPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Упаковочные накладные",
    routeObject: {
      path: LinkPagesEnum.PackageBills,
      element: <SuspenseWrapper><PackageBillsPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Создание накладной упаковки",
    routeObject: {
      path: LinkPagesEnum.AddingPackageBill,
      element: <SuspenseWrapper><AddingPackageBillPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Создание комплекта",
    routeObject: {
      path: LinkPagesEnum.AddingKit,
      element: <SuspenseWrapper><AddingKitPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Счет",
    routeObject: {
      path: LinkPagesEnum.PaymentBill,
      element: <SuspenseWrapper><PaymentBillPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Счета",
    routeObject: {
      path: LinkPagesEnum.PaymentBills,
      element: <SuspenseWrapper><PaymentBillsPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Процесс отгрузки",
    routeObject: {
      path: LinkPagesEnum.ShipProcess,
      element: <SuspenseWrapper><ShipProcessPage /></SuspenseWrapper>,
    }
  },
  {
    pageName: "Процесс приёмки",
    routeObject: {
      path: LinkPagesEnum.InspectionProcess,
      element: <SuspenseWrapper><InspectionProcessPage /></SuspenseWrapper>,
    }
  },
  {
      pageName: "Регистрация",
      routeObject: {
        path: LinkPagesEnum.Registration,
        element: <SuspenseWrapper><RegistrationPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Информация",
      routeObject: {
        path: LinkPagesEnum.Information,
        element: <InformationPage />,
      }
    },
    {
      pageName: "Авторизация",
      routeObject: {
        path: LinkPagesEnum.Authorization,
        element: <SuspenseWrapper><AuthorizationPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Профиль",
      routeObject: {
        path: LinkPagesEnum.UserProfile,
        element:<SuspenseWrapper><UserProfilePage/></SuspenseWrapper>
      }
    },
    {
      pageName: "Главная",
      routeObject: {
        path: LinkPagesEnum.Home,
        element: <SuspenseWrapper><HomePage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Документы",
      routeObject: {
        path: LinkPagesEnum.Documents,
        element: <SuspenseWrapper><DocumentsPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Отгрузка",
      routeObject: {
        path: LinkPagesEnum.Ship,
        element: <SuspenseWrapper><ShipPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Накладные на приход",
      routeObject: {
        path: LinkPagesEnum.AcceptanceBills,
        element: <SuspenseWrapper><AcceptanceBillsPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Накладные на отгрузку",
      routeObject: {
        path: LinkPagesEnum.ShipmentBills,
        element: <SuspenseWrapper><ShipmentBillsPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Накладная на отгрузку",
      routeObject: {
        path: LinkPagesEnum.RedactShipmentBill,
        element: <SuspenseWrapper><RedactShipmentBillPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Накладная на приход",
      routeObject: {
        path: LinkPagesEnum.RedactAcceptanceBill,
        element: <SuspenseWrapper><RedactAcceptanceBillPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Накладная на приход",
      routeObject: {
        path: LinkPagesEnum.AddingAcceptanceBill,
        element: <SuspenseWrapper><AddingAcceptanceBillPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Накладная на отгрузку",
      routeObject: {
        path: LinkPagesEnum.AddingShipmentBill,
        element: <SuspenseWrapper><AddingShipmentBillPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Товары",
      routeObject: {
        path: LinkPagesEnum.Products,
        element: <SuspenseWrapper><ProductsPage /></SuspenseWrapper>
      }
    },
    {
      pageName: "Инветаризация",
      routeObject: {
        path: LinkPagesEnum.Inventory,
        element: <SuspenseWrapper><InventoryPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Ячейки",
      routeObject: {
        path: LinkPagesEnum.Cells,
        element: <SuspenseWrapper><CellsPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Дашбоард",
      routeObject: {
        path: LinkPagesEnum.Terminal,
        element: <SuspenseWrapper><TerminalPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Приёмка",
      routeObject: {
        path: LinkPagesEnum.Inspection,
        element: <SuspenseWrapper><InspectionPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Настройки",
      routeObject: {
        path: LinkPagesEnum.Settings,
        element: <SuspenseWrapper><SettingsPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Перемещение",
      routeObject: {
        path: LinkPagesEnum.PlacementProcess,
        element: <SuspenseWrapper><PlacementProcessPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Перемещение",
      routeObject: {
        path: LinkPagesEnum.Placement,
        element: <SuspenseWrapper><PlacementPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Упаковка",
      routeObject: {
        path: LinkPagesEnum.Package,
        element: <SuspenseWrapper><PackagePage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Упаковка",
      routeObject: {
        path: LinkPagesEnum.PackageProcess,
        element: <SuspenseWrapper><PackageProcessPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Сотрудники",
      routeObject: {
        path: LinkPagesEnum.Employees,
        element: <SuspenseWrapper><EmployeesPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Пользователи",
      routeObject: {
        path: LinkPagesEnum.Users,
        element: <SuspenseWrapper><UsersPage/></SuspenseWrapper>,
      }
    },
    {
      pageName: "Скоро",
      routeObject: {
        path: LinkPagesEnum.Soon,
        element: <SuspenseWrapper><SoonPage/></SuspenseWrapper>,
      }
    },
    {
      pageName: "Процессы",
      routeObject: {
        path: LinkPagesEnum.Processes,
        element: <SuspenseWrapper><ProcessesPage/></SuspenseWrapper>,
      }
    },
    {
      pageName: "Рейтинг складов",
      routeObject: {
        path: LinkPagesEnum.WarehouseRating,
        element: <SuspenseWrapper><WarehouseRatingPage /></SuspenseWrapper>,
      }
    },
    {
      pageName: "Создание заявки",
      routeObject: {
        path: LinkPagesEnum.CreateTicket,
        element: <SuspenseWrapper><CreateTicketPage /></SuspenseWrapper>,
      }
    }
];

const Pages = () => {
  // const user = useAppSelector(state => state.userReducer)

  // const browserRouterUnAuthLoader = (to:string) => {
  //   const userAuthorized = user.token;
  //   const accountNotSelectedType = user.code == "auth3";
  //   if(!userAuthorized){
  //       return redirect("/information")
  //     }
  //     console.log("aaaaaaaaaaaaaaa")
  //     if (accountNotSelectedType) {
  //       return redirect(LinkPagesEnum.UserTypeSelect)
  //     }
  //     return {}
  // }

  // const browserRouterAuthLoader = () =>{
  //   const userAuthorized = user.token;
  //   const accountNotSelectedType = user.code == "auth3";
  //   console.log(user)
  //   if(userAuthorized){
  //     console.log("eeeeeeeeeeeeeee")
  //     if (accountNotSelectedType){
  //       return redirect(LinkPagesEnum.UserTypeSelect)
  //     }

  //     return redirect("/")
  //   }
  //   return {}
  // }

  const router = createBrowserRouter(routes.map(el => {
    // Wrap the element with startTransition if it's not a redirect
    // if(el.routeObject.path != LinkPagesEnum.Authorization 
      //   && el.routeObject.path != LinkPagesEnum.Registration
      //   && el.routeObject.path != LinkPagesEnum.UserTypeSelect
      //   && el.routeObject.path !=  LinkPagesEnum.Information){
      //   el.routeObject.loader = () => browserRouterUnAuthLoader(el.routeObject.path!)
      // }
      // else{
      //   el.routeObject.loader = () => browserRouterAuthLoader()
      // }
  
    return el.routeObject;
  }));

  return (
      // TODO: может заменить это убрать и просто верхним элементом сделать layout 
      // и в нем сделать Suspense для контента(ну suspense в pages)
      // а пропсы в layout сделать через global state ?
      <RouterProvider future={{v7_startTransition: true}} router={router} />
  );
};

export default Pages; 