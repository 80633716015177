import { CSSProperties } from "react";
import styles from "./index.module.scss";
import clsx from "clsx";
import { Loader } from "../Loader";
import { alertsSlice, AlertType } from "app/store/reducers/alertsSlice";
import { store } from "app/ui";

type Props = {
    text: string,
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    disabled?: boolean,
    disabledText?: string,
    className?: string,
    inlineStyles?: CSSProperties;
    isLoader?: boolean;
    loaderText?: string;
    isSelected?: boolean;
};

const Button = ({text, onClick, disabled, disabledText, className, inlineStyles, isLoader, loaderText="Обновление...", isSelected}: Props) => {
    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        
        if (disabled) {
            store.dispatch(alertsSlice.actions.setAlert({
                title: "",
                message: disabledText || "Действие недоступно",
                type: AlertType.Warning,
                duration: 10000,
            }));
            return;
        }

        if (!isLoader) {
            onClick(e as any);
        }
    };

    return (
        <div 
            style={{position:"relative"}} 
            onClick={handleClick}
        >
            <button 
                style={inlineStyles} 
                className={clsx(
                    styles.button, 
                    !isLoader ? styles.button_hover : styles.button_loading, 
                    className, 
                    isSelected ? styles.selectedClassForChangeBackColor : '',
                    disabled && styles.disabled
                )}
            >
                {isLoader && <Loader inlineContainerClasses={styles.loader} inlineStyles={{width: 20, height: 20, borderWidth: 1}} />}
                {isLoader ? loaderText : text}
            </button>
        </div>
    );
};

export {Button};