import React, {ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks/redux";
import { userSlice } from "../store/reducers/UserSlice";
import { useGetUsersMeQuery } from "api";
import { LinkPagesEnum } from "app/store/enums/linkPages";

type Props = {
  children: ReactNode
}

const UserProvider = ({children}: Props) =>  {
    const [loader, setLoader] = useState(true)
    const dispatch = useAppDispatch();
    const {setMe} = userSlice.actions
    const {token} = useAppSelector(el => el.userReducer);

    const isNotConfPage = [LinkPagesEnum.Authorization, 
      LinkPagesEnum.Registration, 
      LinkPagesEnum.Information, 
      LinkPagesEnum.UserTypeSelect].find((page) => window.location.pathname == page) != null;

    const {data = null, isSuccess, isError, error} = useGetUsersMeQuery(undefined, {skip: token == null || isNotConfPage});

    useLayoutEffect(()=> {
      const token = localStorage.getItem("authToken");
      const code = localStorage.getItem("code");

      // console.log(window.location.pathname)
      // console.log(isNotConfPage)
      // console.log("!!!!!!!!11")

      if (token != null && code == "auth3")  {
        if (window.location.pathname != LinkPagesEnum.UserTypeSelect) { 
          window.location.replace(LinkPagesEnum.UserTypeSelect);
        }
        return;
      }
      else if (token != null && (code == null || code == "")) {
        if (isNotConfPage) {
          window.location.replace(LinkPagesEnum.Home);
          return;
        }
      }
      else if (token == null) {
        if (!isNotConfPage) {
          window.location.replace(LinkPagesEnum.Authorization);
          return;
        }
        else if (window.location.pathname == LinkPagesEnum.UserTypeSelect) {
          window.location.replace(LinkPagesEnum.Authorization);
          localStorage.removeItem("code");
        }
      }
      if (data != null) {
        dispatch(setMe(data));
        setLoader(false);
      }
    },[data])

    useEffect(() => {
      if (isError) {
        const code = localStorage.getItem("code");
        if (code == "auth3") {
          if (window.location.pathname!= LinkPagesEnum.UserTypeSelect) {
            window.location.replace(LinkPagesEnum.UserTypeSelect);
          }
        }
        else {
          localStorage.removeItem("authToken");
          window.location.replace(LinkPagesEnum.Authorization);
        }
      }
      if (isSuccess) {
        localStorage.removeItem("code");
        if (isNotConfPage) {
          window.location.replace(LinkPagesEnum.Home);
        }
      }
      setLoader(false);
    }, [isError, isSuccess])

  return (
    <>
    {loader && token != null ? null : children}
    </>
  )
}

export default UserProvider;