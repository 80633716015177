import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/store/hooks/redux';
import { ReactNode } from 'react';
import styles from './index.module.scss'
import { alertsSlice, AlertType } from 'app/store/reducers/alertsSlice';
import clsx from 'clsx';
import { CloseIcon } from 'shared/ui/icons/icons';
import { store } from 'app/ui';

export type IAlertProps = {
    children: ReactNode
};

export const AlertModal = ({children}: IAlertProps) => {
    const errors = useAppSelector(state => state.alertsReducer);
    const {removeAlert, startRemoveAlert} = alertsSlice.actions;
    const dispatch = useAppDispatch();

    useEffect(() => {
        errors.forEach(el => {
            if (!el.isStartedRemove) {
                dispatch(startRemoveAlert(el.identifier));
                setTimeout(() => {
                    dispatch(removeAlert(el.identifier));
                }, el.duration);
            }
        });
    }, [errors]);

    const alertType = (alertType : AlertType) => {
        switch(alertType) {
            case AlertType.Error:  
              return styles.error

              case AlertType.Success:  
              return styles.ok

              case AlertType.Warning:  
              return styles.warning

            default:
                return styles.error
          }
    }

    return (
    <>
        {
            errors.length > 0 
            ? <div className={styles.container}>
                {
                    errors.map((el,index) => 
                        <div key={"alert" + el.identifier} className={clsx(styles.alertWindow, alertType(el.type))}>
                            <div className={styles.close} onClick={() => dispatch(removeAlert(el.identifier))}>
                                <CloseIcon stroke='colorCurrent' />
                            </div>
                            {el.message}
                            <div style={{animationDuration: `${el.duration / 1000}s`}} className={styles.decreasingLine}></div>
                        </div>) 
                }
            </div>
            : null
        }
        {children}    
    </> 
  );
}
