import React from 'react';
import { VisitAnalytics } from 'api';

interface AnalyticsOverviewProps {
    analyticsData: VisitAnalytics;
}

const AnalyticsOverview: React.FC<AnalyticsOverviewProps> = ({ analyticsData }) => {
    const isPC = window.innerWidth > 768;

    return (
        <div style={{ marginBottom: isPC ? 30 : 20 }}>
            <h3 style={{ 
                marginBottom: isPC ? 15 : 10, 
                fontSize: isPC ? 18 : 16,
                color: '#003366'
            }}>Analytics Overview</h3>
            <div style={{ 
                display: 'grid', 
                gridTemplateColumns: isPC ? 'repeat(5, 1fr)' : 'repeat(2, 1fr)', 
                gap: isPC ? '20px' : '10px',
                background: '#f5f5f5',
                padding: isPC ? '25px' : '15px',
                borderRadius: 8
            }}>
                {[
                    {
                        value: analyticsData?.totalVisits || 0,
                        label: 'Total Visits',
                        description: 'All page interactions'
                    },
                    {
                        value: analyticsData?.uniqueUsers || 0,
                        label: 'Unique Users',
                        description: 'Distinct visitors'
                    },
                    {
                        value: analyticsData?.returnVisitorCount || 0,
                        label: 'Return Visitors',
                        description: analyticsData?.returnVisitorRate ? 
                            `${(analyticsData.returnVisitorRate * 100).toFixed(1)}% return rate` : 
                            'Returning users'
                    },
                    {
                        value: analyticsData?.totalRecallRequests || 0,
                        label: 'Recall Requests',
                        description: 'Contact form submissions'
                    },
                    {
                        value: analyticsData?.averageVisitDuration ? 
                            Math.round(analyticsData.averageVisitDuration) + 's' : '0s',
                        label: 'Avg. Visit Duration',
                        description: 'Time spent on site'
                    },
                    {
                        value: analyticsData?.averageSessionLength ? 
                            Math.round(analyticsData.averageSessionLength) + 's' : '0s',
                        label: 'Avg. Session Length',
                        description: 'Active engagement time'
                    },
                    {
                        value: analyticsData?.averageInteractionsPerVisit ? 
                            Math.round(analyticsData.averageInteractionsPerVisit) : '0',
                        label: 'Avg. Interactions',
                        description: 'Mouse/touch events per visit'
                    },
                    {
                        value: analyticsData?.bounceRate ? 
                            (analyticsData.bounceRate * 100).toFixed(1) + '%' : '0%',
                        label: 'Bounce Rate',
                        description: 'Single-interaction visits'
                    },
                    {
                        value: analyticsData?.conversionRate ? 
                            (analyticsData.conversionRate * 100).toFixed(1) + '%' : '0%',
                        label: 'Conversion Rate',
                        description: 'Requests per unique user'
                    }
                ].map((item, index) => (
                    <div key={index} style={{
                        padding: isPC ? '15px' : '10px',
                        background: '#fff',
                        borderRadius: 6,
                        boxShadow: '0 1px 3px rgba(0,0,0,0.1)'
                    }}>
                        <div style={{ 
                            fontSize: isPC ? '28px' : '20px', 
                            fontWeight: 'bold', 
                            color: '#003366',
                            marginBottom: isPC ? 8 : 4
                        }}>
                            {item.value}
                        </div>
                        <div style={{ 
                            fontSize: isPC ? '14px' : '11px', 
                            color: '#666',
                            marginBottom: isPC ? 4 : 2
                        }}>
                            {item.label}
                        </div>
                        <div style={{ 
                            fontSize: isPC ? '12px' : '10px', 
                            color: '#888'
                        }}>
                            {item.description}
                        </div>
                    </div>
                ))}
            </div>
            
            <div style={{ 
                marginTop: isPC ? 25 : 15,
                display: 'grid',
                gridTemplateColumns: isPC ? 'repeat(3, 1fr)' : '1fr',
                gap: isPC ? '20px' : '15px'
            }}>
                {/* Time Distribution */}
                {(analyticsData?.visitsByHour || analyticsData?.visitsByDay) && (
                    <div style={{ 
                        padding: isPC ? '20px' : '15px',
                        background: '#fff',
                        borderRadius: 8,
                        border: '1px solid #eee'
                    }}>
                        <div style={{ 
                            fontSize: isPC ? '16px' : '14px', 
                            fontWeight: 'bold', 
                            marginBottom: isPC ? 15 : 10, 
                            color: '#003366' 
                        }}>
                            Visit Distribution
                        </div>
                        {analyticsData.visitsByHour && (
                            <div style={{ marginBottom: isPC ? 15 : 10 }}>
                                <div style={{ 
                                    fontSize: isPC ? '14px' : '12px', 
                                    color: '#666', 
                                    marginBottom: 5 
                                }}>Peak Hours:</div>
                                <div style={{ fontSize: isPC ? '13px' : '11px', color: '#888' }}>
                                    {Object.entries(analyticsData.visitsByHour)
                                        .sort(([,a], [,b]) => b - a)
                                        .slice(0, 3)
                                        .map(([hour, count]) => (
                                            `${hour}:00 (${count} visits)`
                                        ))
                                        .join(', ')}
                                </div>
                            </div>
                        )}
                        {analyticsData.visitsByDay && (
                            <div>
                                <div style={{ 
                                    fontSize: isPC ? '14px' : '12px', 
                                    color: '#666', 
                                    marginBottom: 5 
                                }}>Busiest Days:</div>
                                <div style={{ fontSize: isPC ? '13px' : '11px', color: '#888' }}>
                                    {Object.entries(analyticsData.visitsByDay)
                                        .sort(([,a], [,b]) => b - a)
                                        .slice(0, 3)
                                        .map(([day, count]) => (
                                            `${day} (${count} visits)`
                                        ))
                                        .join(', ')}
                                </div>
                            </div>
                        )}
                    </div>
                )}

                {/* Screen Resolutions */}
                {analyticsData?.commonScreenResolutions && analyticsData.commonScreenResolutions.length > 0 && (
                    <div style={{ 
                        padding: isPC ? '20px' : '15px',
                        background: '#fff',
                        borderRadius: 8,
                        border: '1px solid #eee'
                    }}>
                        <div style={{ 
                            fontSize: isPC ? '16px' : '14px', 
                            fontWeight: 'bold', 
                            marginBottom: isPC ? 15 : 10, 
                            color: '#003366' 
                        }}>
                            Common Screen Resolutions
                        </div>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: isPC ? '12px' : '10px' }}>
                            {analyticsData.commonScreenResolutions.map((resolution, index) => (
                                <div key={index} style={{ 
                                    fontSize: isPC ? '13px' : '11px',
                                    color: '#666',
                                    padding: isPC ? '6px 12px' : '4px 8px',
                                    background: '#f5f5f5',
                                    borderRadius: 4
                                }}>
                                    w{resolution.width} x h{resolution.height} ({resolution.count} visits)
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                
                {/* Analytics Insights */}
                <div style={{ 
                    padding: isPC ? '20px' : '15px',
                    background: '#fff',
                    borderRadius: 8,
                    border: '1px solid #eee'
                }}>
                    <div style={{ 
                        fontSize: isPC ? '16px' : '14px', 
                        fontWeight: 'bold', 
                        marginBottom: isPC ? 15 : 10, 
                        color: '#003366' 
                    }}>
                        Key Insights
                    </div>
                    <div style={{ 
                        fontSize: isPC ? '14px' : '12px', 
                        color: '#666', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: isPC ? '12px' : '8px' 
                    }}>
                        {analyticsData?.returnVisitorRate && (
                            <div>
                                • Return visitor rate of {(analyticsData.returnVisitorRate * 100).toFixed(1)}% indicates 
                                {analyticsData.returnVisitorRate > 0.3 ? ' strong user retention' : ' opportunity to improve retention'}
                            </div>
                        )}
                        {analyticsData?.bounceRate && (
                            <div>
                                • Bounce rate of {(analyticsData.bounceRate * 100).toFixed(1)}% is
                                {analyticsData.bounceRate < 0.4 ? ' healthy' : ' higher than desired'} 
                                {analyticsData.bounceRate >= 0.4 ? ', consider improving initial engagement' : ''}
                            </div>
                        )}
                        {analyticsData?.conversionRate && (
                            <div>
                                • Conversion rate of {(analyticsData.conversionRate * 100).toFixed(1)}% is 
                                {analyticsData.conversionRate > 0.05 ? ' above' : ' below'} industry average (5%)
                            </div>
                        )}
                        {analyticsData?.averageSessionLength && (
                            <div>
                                • Average session length of {Math.round(analyticsData.averageSessionLength)}s suggests
                                {analyticsData.averageSessionLength > 120 ? ' deep user engagement' : ' potential to improve content engagement'}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AnalyticsOverview; 