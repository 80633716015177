import { Position } from "api";

type IPosition = Position & {
    time?: string;
    isTouch?: boolean;
};

class MouseTracker {
    private positions: IPosition[] = [];
    private currentPositions: IPosition[] = [];
    private animationTimeout: NodeJS.Timeout | null = null;
    private isAnimating: boolean = false;
    private pointsContainer: HTMLDivElement | null = null;
    private onAnimationStateChange: (isAnimating: boolean) => void;
    private playbackSpeed: number = 1;
    private allPoints: IPosition[] = []; // Store all points for animation
    private currentIndex: number = 0; // Track current position in animation
    private isMobile: boolean;
    private autoScroll: boolean = true;

    constructor(containerId: string, onAnimationStateChange: (isAnimating: boolean) => void) {
        this.onAnimationStateChange = onAnimationStateChange;
        // Check if device is mobile
        this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        this.initializeContainer(containerId);
    }

    private initializeContainer(containerId: string) {
        const container = document.getElementById(containerId);
        if (!container) return;

        this.pointsContainer = document.createElement('div');
        this.pointsContainer.style.position = 'absolute';
        this.pointsContainer.style.top = '0';
        this.pointsContainer.style.left = '0';
        this.pointsContainer.style.width = '100%';
        this.pointsContainer.style.height = '100%';
        this.pointsContainer.style.pointerEvents = 'none';
        this.pointsContainer.style.zIndex = '9999';
        container.appendChild(this.pointsContainer);
    }

    private scrollToPoint(x: number, y: number) {
        if (!this.autoScroll) return;

        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;
        const buffer = 100; // Buffer space from edges

        const targetX = Math.max(0, x - viewportWidth / 2);
        const targetY = Math.max(0, y - viewportHeight / 2);

        window.scrollTo({
            left: targetX,
            top: targetY,
            behavior: 'smooth'
        });
    }

    private drawPoint(pos: IPosition, index: number, total: number) {
        if (!this.pointsContainer || typeof pos.x !== 'number' || typeof pos.y !== 'number') return;

        const point = document.createElement('div');
        point.style.position = 'absolute';
        point.style.left = `${pos.x}px`;
        point.style.top = `${pos.y}px`;
        
        // Adjust point size based on device type and interaction type
        const baseSize = this.isMobile ? 16 : 8;
        const size = pos.isTouch ? baseSize * 1.5 : baseSize;
        point.style.width = `${size}px`;
        point.style.height = `${size}px`;
        
        point.style.borderRadius = '50%';
        point.style.backgroundColor = pos.isTouch ? 
            `rgba(0, 120, 255, ${Math.max(0.3, 1 - (index / total))})` : 
            `rgba(255, 0, 0, ${Math.max(0.3, 1 - (index / total))})`;
        point.style.pointerEvents = 'none';
        point.style.zIndex = '9999';
        
        // Add box shadow for better visibility on mobile
        if (this.isMobile) {
            point.style.boxShadow = '0 0 4px rgba(0,0,0,0.3)';
        }

        if (pos.time) {
            const eventType = pos.isTouch ? 'Touch' : 'Move';
            point.title = `${eventType} at ${new Date(pos.time).toLocaleString()}`;
        }

        this.pointsContainer.appendChild(point);

        // If this is the last point in the current chunk, scroll to it
        if (index === this.currentPositions.length - 1) {
            this.scrollToPoint(pos.x, pos.y);
        }
    }

    private clearDrawing() {
        if (this.pointsContainer) {
            this.pointsContainer.innerHTML = '';
        }
    }

    startAnimation(points: IPosition[], speed: number = 1, autoScroll: boolean = true) {
        if (!points || points.length === 0) return;

        // Clear any existing animation
        this.stopAnimation();
        
        this.isAnimating = true;
        this.playbackSpeed = speed;
        this.autoScroll = autoScroll;
        this.onAnimationStateChange(true);
        this.currentPositions = [];
        this.clearDrawing();
        
        // Store all points and reset index
        this.allPoints = [...points];
        this.currentIndex = 0;

        this.showNextChunk();
    }

    private showNextChunk() {
        if (this.currentIndex < this.allPoints.length) {
            const CHUNK_SIZE = 5;
            const endIndex = Math.min(this.currentIndex + CHUNK_SIZE, this.allPoints.length);
            this.currentPositions = this.allPoints.slice(0, endIndex);
            
            // Clear previous points and draw new ones
            this.clearDrawing();
            this.currentPositions.forEach((pos, idx) => {
                this.drawPoint(pos, idx, this.currentPositions.length);
            });

            this.currentIndex = endIndex;

            if (this.currentIndex < this.allPoints.length) {
                // Adjust interval based on playback speed
                const BASE_INTERVAL = 100;
                const adjustedInterval = BASE_INTERVAL / this.playbackSpeed;
                this.animationTimeout = setTimeout(() => this.showNextChunk(), adjustedInterval);
            } else {
                this.stopAnimation();
            }
        } else {
            this.stopAnimation();
        }
    }

    stopAnimation() {
        if (this.animationTimeout) {
            clearTimeout(this.animationTimeout);
            this.animationTimeout = null;
        }
        this.isAnimating = false;
        this.onAnimationStateChange(false);
        this.allPoints = [];
        this.currentIndex = 0;
    }

    clearPositions() {
        this.stopAnimation();
        this.currentPositions = [];
        this.clearDrawing();
    }

    addPosition(x: number, y: number, isTouch: boolean = false) {
        const newPosition: IPosition = {
            x: Math.round(x),
            y: Math.round(y),
            time: new Date().toISOString(),
            isTouch
        };
        this.positions.push(newPosition);
    }

    getPositions() {
        return [...this.positions];
    }

    clearTrackingPositions() {
        this.positions = [];
    }

    dispose() {
        this.stopAnimation();
        this.positions = [];
        this.currentPositions = [];
        if (this.pointsContainer && this.pointsContainer.parentNode) {
            this.pointsContainer.parentNode.removeChild(this.pointsContainer);
        }
        this.pointsContainer = null;
    }

    setPlaybackSpeed(speed: number) {
        if (speed > 0) {
            this.playbackSpeed = speed;
            // If animation is currently running, continue from current position
            if (this.isAnimating && this.allPoints.length > 0) {
                // Clear current timeout and continue from current position
                if (this.animationTimeout) {
                    clearTimeout(this.animationTimeout);
                }
                this.showNextChunk();
            }
        }
    }

    getPlaybackSpeed(): number {
        return this.playbackSpeed;
    }

    setAutoScroll(enabled: boolean) {
        this.autoScroll = enabled;
    }

    getAutoScroll(): boolean {
        return this.autoScroll;
    }
}

export default MouseTracker; 