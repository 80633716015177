import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum AlertType {
    Warning = 'warning',
    Error = 'error',
    Success = 'success'
}

export type AlertMessage = {
    title: string,
    message: string | null,
    type: AlertType,
    identifier: string,
    duration: number,
    startDate: number,
    isStartedRemove: boolean
}

const initialState: Array<AlertMessage> = [];

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        setAlert(state, action: PayloadAction<Omit<AlertMessage, 'startDate' | 'isStartedRemove' | 'identifier'> & {identifier?: string | undefined | null}>) {
           return [...state, 
                {...action.payload, 
                    startDate: Date.now(), 
                    isStartedRemove: false, 
                    identifier: action.payload.identifier || Date.now().toString()
                }];
        },
        removeAlert(state, action: PayloadAction<string>) {
            return [...state].filter(el => el.identifier != action.payload);
        },
        startRemoveAlert(state, action: PayloadAction<string>) {
            return [...state].map(el => el.identifier == action.payload ? {...el, isStartedRemove: true} : el);
        }
    }
});

export default alertsSlice.reducer;