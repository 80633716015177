import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import { I4Icon, Logo, PunctirLineIcon, TimerBackIcon } from "shared/ui/icons/icons";
import clsx from "clsx";
import { Input } from "shared/ui/Input";
import { Button } from "shared/ui/Button";
import { Modal } from "shared/ui/Modal";
import { Position, useGetAnalyticsInformationVisitsByUserIdQuery, useGetAnalyticsInformationAnalyticsQuery, useGetAnalyticsInformationUsersQuery, usePostAnalyticsInformationAddRecallRequestMutation, usePostAnalyticsInformationAddVisitMutation, UserAnalytics, VisitData } from "api";
import { IS_PC } from "app/store/consts";
import { useDispatch } from "react-redux";
import  { AlertType, alertsSlice } from "app/store/reducers/alertsSlice";
import MouseTracker from "./mouseTracker";
import AnalyticsOverview from "components/AnalyticsOverview";
import { Loader } from "shared/ui/Loader";
import clarity from "@microsoft/clarity";

function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

type IPosition = Position & {
    datetime?: string;
};

const InformationPage = () => {
    const [name, setName] = useState("");
    const [number, setNumber] = useState("");
    const [startDate, setStartDate] = useState<string>("");
    const [endDate, setEndDate] = useState<string>("");
    const [analyticsStartDate, setAnalyticsStartDate] = useState<string>("");
    const [analyticsEndDate, setAnalyticsEndDate] = useState<string>("");
    const {setAlert, removeAlert} = alertsSlice.actions;
    const [isAdmin, setIsAdmin] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
    const [showVisits, setShowVisits] = useState(false);
    const [showAnalytics, setShowAnalytics] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const mouseTrackerRef = useRef<MouseTracker | null>(null);
    const lastMousePos = useRef<{x: number, y: number} | null>(null);
    
    const [sessionId] = useState(generateUUID());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isPoliticModalOpen, setIsPoliticModalOpen] = useState(false);

    const [addVisit] = usePostAnalyticsInformationAddVisitMutation();
    const [addRecallRequest] = usePostAnalyticsInformationAddRecallRequestMutation();
    const { data: visitsData, isLoading: isVisitsLoading } = useGetAnalyticsInformationVisitsByUserIdQuery(
        { userId: selectedUserId ?? "" },
        { skip: !selectedUserId || !isAdmin }
    );

    const { data: analyticsData, isLoading: isAnalyticsLoading } = useGetAnalyticsInformationAnalyticsQuery(
        { 
            startDate: analyticsStartDate || undefined,
            endDate: analyticsEndDate || undefined
        },
        { skip: !isAdmin }
    );

    const { data: allUsersData, isLoading: isAllUsersLoading } = useGetAnalyticsInformationUsersQuery(
        { 
            startDate: startDate || undefined,
            endDate: endDate || undefined
        },
        { skip: !isAdmin }
    );

    const dispatch = useDispatch();

    const [showUsersList, setShowUsersList] = useState(true);
    const [showSessionsList, setShowSessionsList] = useState(true);

    // Initialize MouseTracker
    useEffect(() => {
        mouseTrackerRef.current = new MouseTracker(
            'mouseTracker',
            (animating) => setIsAnimating(animating)
        );

        return () => {
            if (mouseTrackerRef.current) {
                mouseTrackerRef.current.dispose();
            }
        };
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const adminId = params.get('adminId');
        if (adminId === 'ghelfdoiergnjf13125dijhrenf') {
            setIsAdmin(true);
            setShowVisits(true);
        }
    }, []);

    useEffect(() => {
        let visitInterval: NodeJS.Timeout | null = null;

        if (!isAdmin && mouseTrackerRef.current) {
            // Handle visit tracking when not in admin mode
            const storedUserId = localStorage.getItem("userId");
            const userId = storedUserId || generateUUID();
            
            if (!storedUserId) {
                localStorage.setItem("userId", userId);
            }

            clarity.init("pyx5hpengr");
            clarity.identify(userId, sessionId, "Information Page");

            // Initial visit
            addVisit({visitInputModel: {
                userId: userId,
                sessionId,
                positions: mouseTrackerRef.current.getPositions(),
                screenWidth: window.innerWidth,
                screenHeight: window.innerHeight
            }});
            mouseTrackerRef.current.clearTrackingPositions();
            
            // Set up interval for subsequent visits
            visitInterval = setInterval(() => {
                if (mouseTrackerRef.current) {
                    addVisit({visitInputModel: {
                        userId: userId,
                        sessionId,
                        positions: mouseTrackerRef.current.getPositions(),
                        screenWidth: window.innerWidth,
                        screenHeight: window.innerHeight
                    }});
                    mouseTrackerRef.current.clearTrackingPositions();
                }
            }, 5000);
        }

        return () => {
            if (visitInterval) {
                clearInterval(visitInterval);
            }
        };
    }, [isAdmin, sessionId, addVisit]);

    const mouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isAdmin && !showVisits && mouseTrackerRef.current) {
            lastMousePos.current = {
                x: e.clientX,
                y: e.clientY
            };
            mouseTrackerRef.current.addPosition(
                e.clientX + window.scrollX,
                e.clientY + window.scrollY,
                false
            );
        }
    };

    const handleTouch = (e: React.TouchEvent<HTMLDivElement>) => {
        if (!isAdmin && !showVisits && mouseTrackerRef.current) {
            const touch = e.touches[0];
            if (!touch) return;

            // Prevent default to avoid scrolling while tracking
            if (e.type === 'touchstart') {
                e.preventDefault();
            }

            lastMousePos.current = {
                x: touch.clientX,
                y: touch.clientY
            };
            mouseTrackerRef.current.addPosition(
                touch.clientX + window.scrollX,
                touch.clientY + window.scrollY,
                true
            );
        }
    };

    // Add scroll event listener
    useEffect(() => {
        const handleScroll = () => {
            if (!isAdmin && !showVisits && lastMousePos.current && mouseTrackerRef.current) {
                mouseTrackerRef.current.addPosition(
                    lastMousePos.current.x + window.scrollX,
                    lastMousePos.current.y + window.scrollY,
                    false
                );
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [isAdmin, showVisits]);

    const sendFormData = () => {
        let userId = localStorage.getItem("userId");

        if (!number.trim()) {
            const alert = {
                type: AlertType.Error,
                message: 'Поле "телефон/телеграм" обязательно для заполнения',
                title: '',
                identifier: Math.random().toString(),
                duration: 7000
            };
            dispatch(setAlert(alert));

            // setTimeout(() => {
            //     dispatch(removeAlert(alert.identifier));
            // }, 8000);
            return;
        }

        const alert = {
            type: AlertType.Success,
            message: 'Данные успешно отправлены',
            title: '',
            identifier: Math.random().toString(),
            duration: 8000
        };
        dispatch(setAlert(alert));

        // setTimeout(() => {
        //     dispatch(removeAlert(alert.identifier));
        // }, 8000);

        addRecallRequest({recallRequestInputModel: {
            userId: userId ?? "",
            sessionId,
            name: name,
            contacts: number
        }});

        setIsModalOpen(false);
    };

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
  
    const deadline = "January, 30, 2025";
  
    const getTime = () => {
      const time = Date.parse(deadline) - Date.now();
  
      setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
      setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
      setMinutes(Math.floor((time / 1000 / 60) % 60));
      setSeconds(Math.floor((time / 1000) % 60));
    };
  
    useEffect(() => {
      const interval = setInterval(() => getTime(), 1000);
  
      return () => clearInterval(interval);
    }, [])

    const getWordForm = (number: number, forms: [string, string, string]) => {
        const n = Math.abs(number) % 100;
        const n1 = n % 10;
        if (n > 10 && n < 20) return forms[2];
        if (n1 > 1 && n1 < 5) return forms[1];
        if (n1 === 1) return forms[0];
        return forms[2];
    };

    const [sortField, setSortField] = useState<string>("visits");
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
    const [searchQuery, setSearchQuery] = useState("");
    const [secondarySortField, setSecondarySortField] = useState<string>("lastVisit");

    const handleUserSelect = (userId: string | undefined) => {
        if (userId) {
        setSelectedUserId(userId);
        }
    };

    const getSortedAndFilteredUsers = () => {
        if (!allUsersData) return [];
        
        let filteredUsers = [...allUsersData];
        
        // Apply search filter
        if (searchQuery) {
            filteredUsers = filteredUsers.filter(user => 
                (user.userId || '').toLowerCase().includes(searchQuery.toLowerCase())
            );
        }
        
        // Apply primary and secondary sorting
        filteredUsers.sort((a, b) => {
            let comparison = 0;
            
            // Primary sort
            comparison = compareValues(a, b, sortField);
            
            // If primary sort results in equality, use secondary sort
            if (comparison === 0 && secondarySortField !== sortField) {
                comparison = compareValues(a, b, secondarySortField);
            }
            
            return sortOrder === "asc" ? comparison : -comparison;
        });
        
        return filteredUsers;
    };

    const compareValues = (a: UserAnalytics, b: UserAnalytics, field: string) => {
        let aValue: any = 0;
        let bValue: any = 0;
        
        switch (field) {
            case "visits":
                aValue = a.totalVisits || 0;
                bValue = b.totalVisits || 0;
                break;
            case "lastVisit":
                aValue = new Date(a.lastVisitDate || 0).getTime();
                bValue = new Date(b.lastVisitDate || 0).getTime();
                break;
            case "duration":
                aValue = a.averageVisitDuration || 0;
                bValue = b.averageVisitDuration || 0;
                break;
            case "totalDuration":
                aValue = a.totalDuration || 0;
                bValue = b.totalDuration || 0;
                break;
            case "totalPositions":
                aValue = a.totalPositions || 0;
                bValue = b.totalPositions || 0;
                break;
            case "interactions":
                aValue = a.averageInteractionsPerVisit || 0;
                bValue = b.averageInteractionsPerVisit || 0;
                break;
            case "visitsAndDuration":
                // Combine visits and duration for a composite score
                aValue = (a.totalVisits || 0) * (a.averageVisitDuration || 0);
                bValue = (b.totalVisits || 0) * (b.averageVisitDuration || 0);
                break;
            case "interactionEfficiency":
                // Interactions per minute of visit duration
                aValue = a.averageVisitDuration ? (a.averageInteractionsPerVisit || 0) / (a.averageVisitDuration / 60) : 0;
                bValue = b.averageVisitDuration ? (b.averageInteractionsPerVisit || 0) / (b.averageVisitDuration / 60) : 0;
                break;
            case "engagementScore":
                // Custom engagement score combining multiple metrics
                aValue = calculateEngagementScore(a);
                bValue = calculateEngagementScore(b);
                break;
            default:
                return 0;
        }
        
        if (typeof aValue === 'number' && typeof bValue === 'number') {
            return aValue - bValue;
        }
        return 0;
    };

    const calculateEngagementScore = (user: UserAnalytics) => {
        const visitScore = user.totalVisits || 0;
        const durationScore = (user.averageVisitDuration || 0) / 60; // Convert to minutes
        const interactionScore = user.averageInteractionsPerVisit || 0;
        
        // Weighted combination of normalized scores
        return (visitScore * 0.4) + (durationScore * 0.3) + (interactionScore * 0.3);
    };

    const formatDuration = (seconds: number): string => {
        if (!seconds) return '0s';
        
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.round(seconds % 60);
        
        const parts = [];
        if (hours > 0) parts.push(`${hours}h`);
        if (minutes > 0) parts.push(`${minutes}m`);
        if (remainingSeconds > 0 || parts.length === 0) parts.push(`${remainingSeconds}s`);
        
        return parts.join(' ');
    };

    const formatInteractionRate = (user: UserAnalytics): string => {
        if (!user.averageVisitDuration || user.averageVisitDuration === 0) return '0';
        
        const rate = (user.averageInteractionsPerVisit || 0) / (user.averageVisitDuration / 60);
        return rate.toFixed(1);
    };

    return (
        <>
        <div 
            id="mouseTracker" 
            className={styles.pc} 
            onMouseMove={mouseMove}
            onTouchStart={handleTouch}
            onTouchMove={handleTouch}
            style={{overflow: "hidden"}}
        >
            {isAdmin && showVisits && (
                <div style={{
                    position: 'fixed',
                    top: 20,
                    right: 20,
                    left: window.innerWidth <= 768 
                        ? 20 
                        : isAnimating 
                            ? 'unset'
                            : showAnalytics ? 20 : 'unset',
                    background: 'white',
                    padding: '15px',
                    borderRadius: 8,
                    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    zIndex: 10000,
                    width: window.innerWidth <= 768 
                        ? 'calc(100% - 40px)' 
                        : isAnimating ? '300px' : 'auto',
                    maxHeight: window.innerWidth <= 768 ? 'calc(100vh - 40px)' : 'auto',
                    overflowY: 'auto',
                    transition: 'all 0.3s ease'
                }}>
                    {!isAnimating && (
                        <>
                            <div style={{ 
                                marginBottom: '15px',
                                position: 'sticky',
                                top: 0,
                                background: 'white',
                                paddingBottom: '10px',
                                borderBottom: '1px solid #eee',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    gap: '10px',
                                    flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
                                    alignItems: window.innerWidth <= 768 ? 'stretch' : 'center',
                                    marginBottom: '10px'
                                }}>
                                    <Input
                                        type="date"
                                        value={analyticsStartDate}
                                        onChange={(e) => setAnalyticsStartDate(e.target.value)}
                                        placeholder="Start Date"
                                        classNamesContainer={styles.searchInput}
                                    />
                                    <Input
                                        type="date"
                                        value={analyticsEndDate}
                                        onChange={(e) => setAnalyticsEndDate(e.target.value)}
                                        placeholder="End Date"
                                        classNamesContainer={styles.searchInput}
                                    />
                                </div>
                                <label style={{ 
                                    display: 'flex', 
                                    alignItems: 'center', 
                                    gap: '8px', 
                                    cursor: 'pointer',
                                    fontSize: window.innerWidth <= 768 ? '14px' : '16px'
                                }}>
                                    <input
                                        type="checkbox"
                                        checked={showAnalytics}
                                        onChange={(e) => setShowAnalytics(e.target.checked)}
                                    />
                                    Show Analytics Overview
                                </label>
                            </div>

                            {showAnalytics && (
                                <div style={{
                                    marginBottom: '20px',
                                    padding: isAnalyticsLoading ? "none" : '10px',
                                    background: '#f8f9fa',
                                    borderRadius: '6px',
                                    minHeight: '100px',
                                    position: 'relative'
                                }}>
                                    {isAnalyticsLoading ? (
                                        <Loader />
                                    ) : analyticsData && (
                                        <AnalyticsOverview analyticsData={analyticsData} />
                                    )}
                                </div>
                            )}

                            <h3 style={{ 
                                fontSize: window.innerWidth <= 768 ? '16px' : '18px',
                                marginBottom: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                                userSelect: 'none'
                            }} onClick={() => setShowUsersList(!showUsersList)}>
                                <span>Users Analytics</span>
                                <span style={{ fontSize: '14px' }}>{showUsersList ? '▼' : '▶'}</span>
                            </h3>

                            {showUsersList && (
                                <>
                                    <div style={{
                                        marginBottom: '15px',
                                        display: 'flex',
                                        gap: '10px',
                                        flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
                                        alignItems: window.innerWidth <= 768 ? 'stretch' : 'center'
                                    }}>
                                        <div style={{
                                            display: 'flex',
                                            gap: '10px',
                                            flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
                                            alignItems: window.innerWidth <= 768 ? 'stretch' : 'center',
                                            width: '100%'
                                        }}>
                                            <Input
                                                type="date"
                                                value={startDate}
                                                onChange={(e) => setStartDate(e.target.value)}
                                                placeholder="Start Date"
                                                classNamesContainer={styles.searchInput}
                                            />
                                            <Input
                                                type="date"
                                                value={endDate}
                                                onChange={(e) => setEndDate(e.target.value)}
                                                placeholder="End Date"
                                                classNamesContainer={styles.searchInput}
                                            />
                                        </div>
                                        <Input
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            placeholder="Search users..."
                                            classNamesContainer={styles.searchInput}
                                        />
                                        <select 
                                            value={sortField}
                                            onChange={(e) => setSortField(e.target.value)}
                                            style={{
                                                padding: '8px',
                                                borderRadius: '4px',
                                                border: '1px solid #ddd'
                                            }}
                                        >
                                            <option value="visits">Total Visits</option>
                                            <option value="lastVisit">Last Visit</option>
                                            <option value="duration">Avg Duration</option>
                                            <option value="totalDuration">Total Time</option>
                                            <option value="totalPositions">Total Positions</option>
                                            <option value="interactions">Avg Interactions</option>
                                            <option value="visitsAndDuration">Visit Time Impact</option>
                                            <option value="interactionEfficiency">Interaction Rate</option>
                                            <option value="engagementScore">Engagement Score</option>
                                        </select>
                                        <select 
                                            value={secondarySortField}
                                            onChange={(e) => setSecondarySortField(e.target.value)}
                                            style={{
                                                padding: '8px',
                                                borderRadius: '4px',
                                                border: '1px solid #ddd'
                                            }}
                                        >
                                            <option value="">No Secondary Sort</option>
                                            <option value="visits">Total Visits</option>
                                            <option value="lastVisit">Last Visit</option>
                                            <option value="duration">Avg Duration</option>
                                            <option value="totalDuration">Total Time</option>
                                            <option value="totalPositions">Total Positions</option>
                                            <option value="interactions">Avg Interactions</option>
                                            <option value="visitsAndDuration">Visit Time Impact</option>
                                            <option value="interactionEfficiency">Interaction Rate</option>
                                            <option value="engagementScore">Engagement Score</option>
                                        </select>
                                        <Button
                                            text={sortOrder === "asc" ? "↑" : "↓"}
                                            onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
                                            inlineStyles={{
                                                padding: '8px 12px',
                                                minWidth: '40px'
                                            }}
                                        />
                                    </div>
                                    <div style={{ 
                                        maxHeight: window.innerWidth <= 768 ? 'calc(100vh - 200px)' : '400px',
                                        overflowY: 'auto',
                                        padding: '5px',
                                        minHeight: '100px',
                                        position: 'relative',
                                        marginBottom: '20px'
                                    }}>
                                        {isAllUsersLoading ? (
                                            <Loader />
                                        ) : (
                                            getSortedAndFilteredUsers().map((user, index) => (
                                                <div 
                                                    key={index} 
                                                    style={{
                                                        marginBottom: 10,
                                                        padding: '12px',
                                                        border: '1px solid #eee',
                                                        borderRadius: 4,
                                                        fontSize: window.innerWidth <= 768 ? '14px' : '16px',
                                                        cursor: 'pointer',
                                                        background: selectedUserId === (user.userId || '') ? '#f0f7ff' : '#fff'
                                                    }}
                                                    onClick={() => handleUserSelect(user.userId)}
                                                >
                                                    <div style={{ fontWeight: 'bold' }}>User ID: {user.userId || 'Unknown'}</div>
                                                    <div style={{ 
                                                        display: 'grid', 
                                                        gridTemplateColumns: window.innerWidth <= 768 ? '1fr' : '1fr 1fr',
                                                        gap: '8px',
                                                        marginTop: '8px'
                                                    }}>
                                                        <div style={{ 
                                                            padding: '8px', 
                                                            background: '#f8f9fa', 
                                                            borderRadius: '4px',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: '4px'
                                                        }}>
                                                            <div style={{ fontWeight: '500' }}>Visit Statistics</div>
                                                            <div>Total Visits: {user.totalVisits || 0}</div>
                                                            <div>Last Visit: {new Date(user.lastVisitDate || 0).toLocaleString()}</div>
                                                            <div>Total Time: {formatDuration(user.totalDuration || 0)}</div>
                                                        </div>
                                                        <div style={{ 
                                                            padding: '8px', 
                                                            background: '#f8f9fa', 
                                                            borderRadius: '4px',
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: '4px'
                                                        }}>
                                                            <div style={{ fontWeight: '500' }}>Engagement Metrics</div>
                                                            <div>Avg Duration: {formatDuration(user.averageVisitDuration || 0)}</div>
                                                            <div>Avg Interactions: {Math.round(user.averageInteractionsPerVisit || 0)}/visit</div>
                                                            <div>Interaction Rate: {formatInteractionRate(user)}/min</div>
                                                            <div>Total Positions: {user.totalPositions || 0}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                </>
                            )}

                            {selectedUserId && visitsData && (
                                <>
                                    <h3 style={{ 
                                        fontSize: window.innerWidth <= 768 ? '16px' : '18px',
                                        margin: '20px 0 15px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        cursor: 'pointer',
                                        userSelect: 'none'
                                    }} onClick={() => setShowSessionsList(!showSessionsList)}>
                                        <span>User Sessions</span>
                                        <span style={{ fontSize: '14px' }}>{showSessionsList ? '▼' : '▶'}</span>
                                    </h3>
                                    {showSessionsList && (
                                        <div style={{ 
                                            maxHeight: window.innerWidth <= 768 ? 'calc(100vh - 200px)' : '400px',
                                            overflowY: 'auto',
                                            padding: '5px'
                                        }}>
                                            {isVisitsLoading ? (
                                                <Loader />
                                            ) : visitsData.map((visit, index) => (
                                                <div key={index} style={{
                                                    marginBottom: 10,
                                                    padding: '12px',
                                                    border: '1px solid #eee',
                                                    borderRadius: 4,
                                                    fontSize: window.innerWidth <= 768 ? '14px' : '16px'
                                                }}>
                                                    <div>Session: {visit.sessionId}</div>
                                                    <div>First Visit: {new Date(visit.visitDate!).toLocaleString()}</div>
                                                    <div>Points: {visit.positions?.length || 0}</div>
                                                    <Button 
                                                        text="Show Points"
                                                        onClick={() => {
                                                            if (visit.positions && visit.positions.length > 0 && mouseTrackerRef.current) {
                                                                mouseTrackerRef.current.startAnimation(visit.positions, 1, true);
                                                            }
                                                        }}
                                                        inlineStyles={{
                                                            width: window.innerWidth <= 768 ? '100%' : 'auto',
                                                            marginTop: '8px'
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {isAnimating && (
                        <div style={{ padding: '10px' }}>
                            <Button 
                                text="Stop" 
                                onClick={() => {
                                    if (mouseTrackerRef.current) {
                                        mouseTrackerRef.current.stopAnimation();
                                    }
                                }}
                                inlineStyles={{
                                    width: window.innerWidth <= 768 ? '100%' : 'auto',
                                    marginBottom: '10px'
                                }}
                            />
                            <div style={{ 
                                marginTop: '10px', 
                                display: 'flex', 
                                flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
                                alignItems: window.innerWidth <= 768 ? 'stretch' : 'center',
                                gap: '10px' 
                            }}>
                                <span style={{ fontSize: window.innerWidth <= 768 ? '14px' : '16px' }}>Speed:</span>
                                <div style={{ 
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    gap: '5px',
                                    flex: 1
                                }}>
                                    {[0.5, 1, 2, 4].map((speed) => (
                                        <Button
                                            key={speed}
                                            text={`${speed}x`}
                                            onClick={() => {
                                                if (mouseTrackerRef.current) {
                                                    mouseTrackerRef.current.setPlaybackSpeed(speed);
                                                }
                                            }}
                                            inlineStyles={{
                                                padding: '5px 10px',
                                                opacity: mouseTrackerRef.current?.getPlaybackSpeed() === speed ? 1 : 0.6,
                                                flex: window.innerWidth <= 768 ? 1 : 'none'
                                            }}
                                        />
                                    ))}
                                </div>
                            </div>
                            <div style={{ 
                                marginTop: '10px', 
                                display: 'flex', 
                                flexDirection: window.innerWidth <= 768 ? 'column' : 'row',
                                alignItems: window.innerWidth <= 768 ? 'stretch' : 'center',
                                gap: '10px' 
                            }}>
                                <span style={{ fontSize: window.innerWidth <= 768 ? '14px' : '16px' }}>Auto-scroll:</span>
                                <Button
                                    text={mouseTrackerRef.current?.getAutoScroll() ? "On" : "Off"}
                                    onClick={() => {
                                        if (mouseTrackerRef.current) {
                                            const currentState = mouseTrackerRef.current.getAutoScroll();
                                            mouseTrackerRef.current.setAutoScroll(!currentState);
                                        }
                                    }}
                                    inlineStyles={{
                                        padding: '5px 10px',
                                        opacity: mouseTrackerRef.current?.getAutoScroll() ? 1 : 0.6,
                                        width: window.innerWidth <= 768 ? '100%' : 'auto'
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}
            <div className={styles.container}>
                <div className={clsx(styles.header, styles.width)}>
                    <Logo inlineClass={styles.logo} fill="#003366"/>
                    <div className={clsx(styles.header__container, styles.color_white, styles.mobile)}>
                        <div className={styles.header__margin}>
                            <a href="#opportunities">
                                Возможности
                            </a>
                        </div>
                        <div>
                            <a href="#advantages">
                                Преимущества
                            </a>
                        </div>
                        <div className={clsx(styles.button, styles.header__marginLeft)} onClick={() => setIsModalOpen(true)}>
                            Оставить заявку
                        </div>
                    </div>
                </div>
            </div>
            <div className={clsx(styles.container, styles.container_blue)} >
                <div className={clsx(styles.form, styles.width)}>
                    <div className={styles.form__container}>
                        <div className={styles.h2}>
                            Повышайте продуктивность вашего склада совместно с нами!
                        </div>
                        <div className={styles.h2_sub}>
                            Извлеките всю выгоду из современных методов управления складом.
                        </div>
                        <div className={clsx(styles.button, styles.button_white)} onClick={() =>  setIsModalOpen(true)}>
                            Оставить заявку
                        </div>
                    </div>
                    {IS_PC ?
                    <img className={styles.img} src="assets/images/information.png" alt="" />
                    : <img className={styles.img} src="assets/images/information-mobile.png" alt="" />
                }
                </div>
            </div>
            <div className={clsx(styles.container)} id="opportunities">
                <div className={clsx(styles.width, styles.block_2)}>
                    <div className={styles.h3} style={{textAlign:"center"}}>
                        Функциональные возможности
                    </div>
                    <div className={styles.functions}>
                        <div className={styles.function}>
                            <img className={styles.icon} src="assets/images/i1.png" alt="" />
                            <div className={styles.function__text}>
                                Одновременное участие нескольких людей в выполнении одного процесса.
                            </div>
                        </div>
                        <div className={styles.function}>
                            <I4Icon width="80px" height="80px" stroke="#003366"/>
                            <div className={styles.function__text}>
                                Автоматическое распределение товаров по складу.
                            </div>
                        </div>
                        <div className={styles.function}>
                            <img className={styles.icon} src="assets/images/i3.png" alt="" />
                            <div className={styles.function__text}>
                                Выполнение процесса может быть приостановлено и закончено в другое время.
                            </div>
                        </div>
                        <div className={styles.function}>
                            <img className={styles.icon} src="assets/images/i6.png" alt="" />
                            <div className={styles.function__text}>
                                Визуальный конструктор схемы склада. 
                            </div>
                        </div>
                        <div className={styles.function}>
                            <img className={styles.icon} src="assets/images/i5.png" alt="" />
                            <div className={styles.function__text}>
                                Карта, отображающая в реальном времени местоположение объектов или людей.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={clsx(styles.container, styles.container_blue)} style={{backgroundColor: "#F1F4FA"}}>
                <div className={clsx(styles.width, styles.block_4)}>
                    <div className={styles.h3} style={{textAlign:"center", maxWidth: 590}}>
                        Что мы сможем сделать
                        для вашего склада
                    </div>
                    <div className={styles.numbers}>
                        <div className={styles.number__wrapper}>
                            <div className={styles.number__container}>
                                <img src="assets/images/information_number_back.png" alt="" />
                                <div className={styles.number}>
                                    1
                                </div>
                            </div>
                            <div className={styles.number__title}>
                                Снижение необходимости в участии человека на складе.
                            </div>
                            <div className={styles.number_subTitle}>
                                Расположение товара будет отслеживаться программой, 
                                а не кладовщиком. Сканирование во время выполнения 
                                операций будет способствовать обнаружению ошибок и 
                                предотвратит их возникновение.
                            </div>
                        </div>
                        <div className={styles.number__wrapper}>
                            <div className={styles.number__container}>
                                <img src="assets/images/information_number_back.png" alt="" />
                                <div className={styles.number}>
                                    2
                                </div>
                            </div>
                            <div className={styles.number__title}>
                                Рост прибыли.                            
                            </div>
                            <div className={styles.number_subTitle}>
                                Автоматизация и оптимизация процессов позволяет ускорить операции на складе, снижая затраты и повышая общую производительность, что увеличивает прибыль.
                            </div>
                        </div>
                        <div className={styles.number__wrapper}>
                            <div className={styles.number__container}>
                                <img src="assets/images/information_number_back.png" alt="" />
                                <div className={styles.number}>
                                    3
                                </div>
                            </div>
                            <div className={styles.number__title}>
                                Рекомендации.
                            </div>
                            <div className={styles.number_subTitle}>
                                Система рекомендаций, основанная на опыте других лидеров, точках зрения ведущих аналитиков и предложениях ИИ.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.container} style={{backgroundColor: "#fff"}} id="advantages">
                <div className={clsx(styles.width, styles.block_5)}>
                    <div className={styles.h3} style={{textAlign:"center", maxWidth: 590}}>
                        Наши преимущества над
                        конкурентами
                    </div>
                    <div className={styles.pluses}>
                        <div className={styles.plus}>
                            <img src="assets/images/i2.png" alt="" />
                            <div className={styles.plus__text}>
                                Приложение функционирует на новейших интернет-технологиях,
                                отличается быстрым откликом и удобным интерфейсом.
                            </div>
                        </div>
                        <div className={styles.plus}>
                            <img src="assets/images/i9.png" alt="" />
                            <div className={styles.plus__text}>
                                У нас есть функции, которые отсутствуют у 
                                остальных, а те, что имеются, оптимизированы 
                                для более продуктивного использования.
                            </div>
                        </div>
                        <div className={styles.plus}>
                            <img src="assets/images/i8.png" alt="" />
                            <div className={styles.plus__text}>
                                Мы уделяем внимание не только wms системе, 
                                но также активно развиваем робототехнику, 
                                маркетплейс и логистическое предприятие.
                            </div>
                        </div>
                        <div className={styles.plus}>
                            <img src="assets/images/i7.png" alt="" />
                            <div className={styles.plus__text}>
                                Мы молодая команда специалистов и желаем привнести
                                современные инновации, которые поднимут вашу прибыль.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className={styles.container} style={{backgroundColor: "#FFEC1E", position: "relative"}}>
                <div className={clsx(styles.width, styles.block_4, styles.block_action)}>
                    <div className={styles.h3} style={{textAlign:"center", maxWidth: 790, margin: "0 auto"}}>
                        Уникальная акция
                    </div>
                    <div className={styles.action1}>
                        Увеличьте прибыль бесплатно: 30 дней на пробу.
                    </div>
                    <div className={styles.plusIcon}>
                        +
                    </div>
                    <div className={styles.action2}>
                        Если успеете оформить подписку в течение
                    </div>
                    <div className={styles.timer__wrapper}>
                    <div className={styles.timer_part}>
                        <div className={styles.timer__number}>{days}</div>
                            <div className={styles.timer__text}>{getWordForm(days, ['День', 'Дня', 'Дней'])}</div>
                        </div>
                        <div className={styles.timer_part}>
                            <div className={styles.timer__number}>{hours}</div>
                            <div className={styles.timer__text}>{getWordForm(hours, ['Час', 'Часа', 'Часов'])}</div>
                        </div>
                        <div className={styles.timer_part}>
                            <div className={styles.timer__number}>{minutes}</div>
                            <div className={styles.timer__text}>{getWordForm(minutes, ['Минута', 'Минуты', 'Минут'])}</div>
                        </div>
                        <div className={styles.timer__left}>
                            <div className={styles.timer__number}>15%</div>
                            <div className={styles.timer__text}>Получите скидку на следующий месяц.</div>
                        </div>
                        <div className={styles.timer__right}>
                            <div className={styles.timer__number}>40%</div>
                            <div className={styles.timer__text}>Розыгрыш 40% скидки на 3 месяца.</div>
                        </div>
                        <PunctirLineIcon inlineClass={styles.timer__left_line}/>
                        <PunctirLineIcon inlineClass={styles.timer__right_line}/>
                    </div>
                </div>
                <TimerBackIcon inlineClass={styles.timer__back}/>
                <TimerBackIcon inlineClass={styles.timer__back_right}/>
            </div>
            <div className={styles.container} style={{backgroundColor: "#F1F4FA", position: "relative"}}>
                <div className={styles.functions__stick}/>
                <div className={clsx(styles.width, styles.block_4)}>
                    <div className={styles.h3} style={{textAlign:"center", maxWidth: 790}}>
                        Если не хватает существующих 
                        возможностей программы
                    </div>
                    <div className={styles.functions}>

                        <div className={styles.function}>
                            <div className={clsx(styles.number, styles.number_blue)}>
                                1
                            </div>
                            <div className={styles.function__text}>
                                При необходимости можем помочь с проведением анализа.
                            </div>
                        </div>
                        <div className={styles.function}>
                            <div className={clsx(styles.number, styles.number_blue)}>
                                2
                            </div>
                            <div className={styles.function__text}>
                                Выполним необходимые настройки и 
                                доработки программы.
                            </div>
                        </div>
                        <div className={styles.function}>
                            <div className={clsx(styles.number, styles.number_blue)}>
                                3
                            </div>
                            <div className={styles.function__text}>
                                Проведем обучение для пользователей.
                            </div>
                        </div>
                        <div className={styles.function}>
                            <div className={clsx(styles.number, styles.number_blue)}>
                                4
                            </div>
                            <div className={styles.function__text}>
                                Сообщество может дать советы по улучшению доработок.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.container}>
                <div className={clsx(styles.width, styles.block_4)} style={{backgroundColor: "#fff", display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <div className={styles.h3} style={{textAlign:"center", maxWidth: 790, margin: "0 auto 0 auto"}}>
                        Остались вопросы?
                    </div>
                    <div className={styles.block_6} style={{fontSize: 20, fontWeight: 500, textAlign:'center'}}>
                        Получите консультацию нашего менеджера
                    </div>
                    <div className={styles.block_7}>
                        <Input inlineStyles={{borderRadius: 0}} classNamesContainer={clsx(styles.fieds_width, styles.fields_width_margin)} value={name} placeholder="Имя" onChange={(e) => setName(e.target.value)}/>
                        <Input inlineStyles={{borderRadius: 0}} classNamesContainer={clsx(styles.fieds_width, styles.fields_width_margin)} value={number} placeholder="телефон/телеграм" onChange={(e) =>  setNumber(e.target.value)}/>
                        <Button className={styles.fieds_width} inlineStyles={{justifyContent: "center", borderRadius: 0}} text="Получить консультацию" onClick={sendFormData}/>
                    </div>
                </div>
            </div>
            <div className={clsx(styles.footer)}>
                <div className={styles.container}>
                    <div className={clsx(styles.footer__top, styles.width)}>
                        <div className={styles.footer__logoContainer}>
                            <Logo inlineClass={styles.logo} fill="#fff" inlineStyles={{marginRight: "40px"}}/>
                            <div onClick={() => setIsPoliticModalOpen(true)}>
                                <a href="#" style={{color: "#fff"}}>Политика конфиденциальности</a>
                            </div>
                        </div>
                        <div className={clsx(styles.footer__stick, styles.width, styles.block_8)}/>
                        <div className={styles.contacts}>
                            <a style={{marginRight: 10}} href="https://t.me/difwmsru" target="_blank">
                                Телеграм: @difwms
                            </a>
                            <a style={{marginRight: 10}} href="tel:+79196165333">
                                +7 (919) 616-53-33
                            </a>
                            <div>
                                diffwms@gmail.com
                            </div>
                        </div>
                    </div>
                    <div className={clsx(styles.footer__stick, styles.width)}/>
                    <div className={styles.footer__bottom}>
                        2024 © Все права защищены.
                    </div>
                </div>
            </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                close={() => setIsModalOpen(false)}
                inlineStyles={{maxWidth: "97vw"}}
                >
                    <div
                        style={{display: "flex", flexDirection: "column", alignItems: "center"}}
                        >
                        <Logo fill="#003366" inlineClass={styles.logo}/>
                        <div className={styles.modal__title}>Нужна консультация ?</div>
                        <div className={styles.modal__subTitle}>Оставьте свой номер, и мы перезвоним вам</div>
                        <Input inlineStyles={{borderRadius: 0}} classNamesContainer={styles.modal__fiedsWidth} value={name} placeholder="Имя" onChange={(e) => setName(e.target.value)}/>
                        <Input inlineStyles={{borderRadius: 0}} classNamesContainer={styles.modal__fiedsWidth} value={number} placeholder="телефон/телеграм" onChange={(e) =>  setNumber(e.target.value)}/>
                        <Button className={styles.modal__fiedsWidth} inlineStyles={{justifyContent: "center", borderRadius: 0}} text="Получить консультацию" onClick={sendFormData}/>
                    </div>
            </Modal>
            <Modal
                isOpen={isPoliticModalOpen}
                classNames={styles.politicModal}
                close={() => setIsPoliticModalOpen(false)}
            >
                <div className={styles.politic}>
                
                <header>
                    <h1>Политика конфиденциальности</h1>
                </header>
            
                <section>
                    <p>Настоящая Политика конфиденциальности описывает порядок сбора, использования и защиты вашей информации в рамках использования нашей системы управления складом DIF.</p>
            
                    <h2>1. Сбор информации</h2>
                    <p>Мы можем собирать следующую информацию о вас:</p>
                    <ul>
                        <li><strong>Личная информация:</strong> Имя, адрес электронной почты, номер телефона и другие контактные данные.</li>
                        <li><strong>Информация об использовании:</strong> Данные о вашем взаимодействии с системой, включая логины, действия в системе и IP-адреса.</li>
                        <li><strong>Информация о компании:</strong> Название вашего бизнеса и другие относящиеся к делу детали, которые вы предоставляете нам.</li>
                    </ul>
            
                    <h2>2. Использование информации</h2>
                    <p>Мы используем собранную информацию для:</p>
                    <ul>
                        <li>Обеспечения доступа и использования нашей WMS-системы.</li>
                        <li>Улучшения наших услуг и пользовательского опыта.</li>
                        <li>Коммуникации с вами, включая рассылку обновлений и поддерживающих уведомлений.</li>
                        <li>Соблюдения правовых и регуляторных требований.</li>
                    </ul>
            
                    <h2>3. Обмен и раскрытие информации</h2>
                    <p>Мы не будем передавать вашу личную информацию третьим лицам, за исключением следующих случаев:</p>
                    <ul>
                        <li>С вашего согласия.</li>
                        <li>Для соблюдения законов и нормативных требований.</li>
                        <li>В случае продажи или покупки бизнеса, в рамках которого ваши данные могут быть переданы новому владельцу.</li>
                    </ul>
            
                    <h2>4. Защита информации</h2>
                    <p>Мы принимаем коммерчески обоснованные меры для защиты вашей информации от несанкционированного доступа, использования или раскрытия. Однако ни один метод передачи данных через интернет или метод хранения информации не является полностью безопасным, поэтому мы не можем гарантировать абсолютную безопасность.</p>
            
                    <h2>5. Cookies и аналогичные технологии</h2>
                    <p>Мы можем использовать cookies и аналогичные технологии для улучшения вашего опыта пользования и анализа использования нашей системы.</p>
            
                    <h2>6. Ваши права</h2>
                    <p>Вы имеете право запрашивать доступ, исправление или удаление вашей личной информации. Для этого, пожалуйста, свяжитесь с нами по контактной информации, указанной ниже.</p>
            
                    <h2>7. Изменения в Политике конфиденциальности</h2>
                    <p>Мы оставляем за собой право вносить изменения в настоящую Политику конфиденциальности. Мы уведомим вас о любых изменениях, опубликовав новую версию на нашем сайте и указав дату ее вступления в силу.</p>
            
                    <h2>8. Контактная информация</h2>
                    <p>Если у вас есть вопросы или замечания по поводу данной Политики конфиденциальности, пожалуйста, свяжитесь с нами:</p>
                    <p>Email: <a href="mailto:darkhaker03@gmail.com">darkhaker03@gmail.com</a>
                    Телефон: <a href="tel:+79196165333">89196165333</a></p>
                </section>
                </div>
            </Modal>
        </>
    );
};

export {
    InformationPage
};